<template>
  <div class="left-menu-container">
    <div class="menu-collapser">
      <el-button title="折叠/展开菜单栏" type="text" @click="isCollapse=!isCollapse">
        <el-icon v-if="isCollapse">
          <DArrowRight/>
        </el-icon>
        <el-icon v-else>
          <DArrowLeft/>
        </el-icon>
      </el-button>
    </div>

    <el-menu
        :default-active="currentMenuIdx"
        active-text-color="#ffffff"
        background-color="#142233"
        class="el-menu-vertical-demo"
        text-color="#97bdff"
        @select="handleSelect"
        @open="handleOpen"
        @close="handleClose"
        :unique-opened="true"
        :collapse="isCollapse"
    >
      <div v-for="treeItem in treeData" :key="'span_'+treeItem.id">
        <el-sub-menu v-if="treeItem.type==1" :key="treeItem.id" :index="'/'+treeItem.route">
          <template #title>
            <svg-icon :icon-class="treeItem.iconUri || 'app'" className="red"/>
            <span>{{ treeItem.name }}</span>
          </template>
          <el-menu-item-group v-if="treeItem.children && treeItem.children.length>0">
            <el-menu-item v-for="treeSubItem in treeItem.children" :key="treeSubItem.id"
                          :index="treeSubItem.target==0 ? '/'+treeItem.route+'/'+treeSubItem.route : 'link::'+treeSubItem.link">
              <svg-icon :icon-class="treeSubItem.iconUri || 'app'" className="red"/>
              {{ treeSubItem.name }}
            </el-menu-item>
          </el-menu-item-group>
        </el-sub-menu>
        <el-menu-item v-if="treeItem.type==0" :key="treeItem.id"
                      :index="treeItem.target==0 ? '/'+treeItem.route : 'link::'+treeItem.link">
          <svg-icon :icon-class="treeItem.iconUri || 'app'" className="red"/>
          {{ treeItem.name }}
        </el-menu-item>
      </div>
      <!--      <el-sub-menu v-for="treeItem in treeData" :key="treeItem.id" :index="'/'+treeItem.route">-->
      <!--        <template #title>-->
      <!--          <svg-icon :icon-class="treeItem.iconUri || 'app'" className="red" />-->
      <!--          <span>{{ treeItem.name }}</span>-->
      <!--        </template>-->
      <!--        <el-menu-item-group v-if="treeItem.children && treeItem.children.length>0">-->
      <!--          <el-menu-item v-for="treeSubItem in treeItem.children" :key="treeSubItem.id" :index="'/'+treeItem.route+'/'+treeSubItem.route"><svg-icon :icon-class="treeSubItem.iconUri || 'app'" className="red" />{{treeSubItem.name}}</el-menu-item>-->
      <!--        </el-menu-item-group>-->
      <!--      </el-sub-menu>-->
    </el-menu>
  </div>
</template>

<script>
import {store} from '@/store/index'
import {loadMenuTree} from '@/utils/common'
import {getUserInfo} from "@/utils/auth"
import {DArrowRight, DArrowLeft} from '@element-plus/icons-vue'

export default {
  components: {
    DArrowRight,
    DArrowLeft
  },
  data() {
    return {
      treeData: [],
      isCollapse: false,
      currentMenuIdx: null
    }
  },
  mounted() {
    this.currentMenuIdx = window.localStorage.getItem('currentMenu')
    if (!getUserInfo()) this.$router.push('/login')

    loadMenuTree().then(menuTree => {
      this.treeData.splice(0, this.treeData.length)
      menuTree.forEach((item) => {
        this.treeData.push(item)
      })
    })
  },
  methods: {
    handleOpen(key, keyPath) {
      console.log(key, keyPath)
    },
    handleClose(key, keyPath) {
      console.log(key, keyPath)
    },
    handleSelect(index) {
      // console.log(index)
      // console.log(indexPath)
      if (index.indexOf('link::') == 0) {
        let url = index.replace('link::', '')
        let redWindow = window.open(url, '_blank')
        redWindow.location
      } else {
        store.commit('set_currentMenu', index)
        window.localStorage.setItem('currentMenu', index)
        this.$router.push(index)
      }


    }
  }
}
</script>
<style scoped>
/*.el-menu.horizontal-collapse-transition .el-sub-menu__title span{*/
/*  overflow: hidden;*/
/*  visibility: hidden;*/
/*  display: none;*/
/*}*/
.el-menu--collapse .el-sub-menu__title span {
  overflow: hidden;

  visibility: hidden;

  display: none;
}

.el-menu--collapse /deep/ .el-sub-menu__title .el-icon {
  display: none
}

.el-menu--collapse /deep/ .el-sub-menu__title .svg-icon {
  margin-right: 0
}
</style>
