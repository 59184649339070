import {createRouter, createWebHashHistory} from 'vue-router'
import Layout from '@/views/basic/main'
import Cookies from 'js-cookie'
import Setting from '@/settings'
import {loadMenuTree} from '@/utils/common'
import {getUserInfo} from '@/utils/auth'
import {store} from "@/store";


const loadView = (view) => {
    // console.log(`@/views/${view}`)
    return () => import(`@/views/${view}`)
}

export const filterAsyncRouter = (routers) => { // 遍历后台传来的路由字符串，转换为组件对象
    return routers.filter(router => {
        if (router.component) {
            if (router.component === 'Layout') { // Layout组件特殊处理
                router.component = Layout
            } else {
                const component = router.component
                router.component = loadView(component)
            }
        }
        if (router.children && router.children.length) {
            router.children = filterAsyncRouter(router.children)
        }
        return true
    })
}


export const staticRouterMap = [
    {
        path: '/login',
        meta: {title: '登录', noCache: true},
        component: () => import('@/views/auth/login'),
        hidden: true
    },
    // {
    //     path: '/',
    //     component: Layout,
    //     redirect: '/user/userinfo',
    //     children: [
    //         {
    //             path: 'user',
    //             component: () => import('@/views/user/indexPage')
    //         },
    //
    //     ]
    // },
    {
        path: '/',
        component: Layout,
        redirect: '/home/index',
        children: [
            {
                path: 'user',
                component: () => import('@/views/user/indexPage')
            },

        ]
    },
    {
        path: '/home',
        component: Layout,
        children: [
            {
                path: 'index',
                component: () => import('@/views/home/index')
            }
        ]
    },
    {
        path: '/menu',
        meta: {title: '菜单管理', noCache: true},
        component: () => import('@/views/menu/indexPage'),
        hidden: true
    },
    {
        path: '/user',
        component: Layout,
        children: [
            {
                path: 'userinfo',
                component: () => import('@/views/userinfo/indexPage'),
            }
        ]
    },

    // {
    //     path: '/dash',
    //     component: Layout,
    //     children: [
    //         {
    //             path: 'dashboard',
    //             component: () => import('@/views/dashboard/indexPage'),
    //         }
    //     ]
    // },


]

// routes that do not need auth.
export const passRoutes = [
    '/login'
]

export const router = createRouter({
    history: createWebHashHistory(),
    scrollBehavior: () => ({y: 0}),
    routes: staticRouterMap
})


/**
 * loadRouters
 * load routers from menu data.
 * @returns {Promise<unknown>}
 */
export const loadRouters = () => {

    return new Promise((resolve, reject) => {
        loadMenuTree().then(menuTree => {

            console.log(menuTree)
            const generate = (curList, routeData, parent) => {
                parent = parent || '/'
                if (!curList) return
                curList.forEach((item) => {

                    if (parent == '/') {
                        const newRout = {
                            name: item.name,
                            path: '/' + item.route,
                            component: 'Layout',
                            redirect: 'noredirect',
                            children: []
                        }
                        routeData.push(newRout)
                        generate(item.children, newRout.children, item.route)
                    } else {
                        routeData.push({
                            name: item.name,
                            path: item.route,
                            component: item.link,
                        })
                    }
                })
            }

            const routers = []

            generate(menuTree, routers, '/')

            console.log(routers)
            resolve(routers)


        }).catch(err => {
            console.log(err)
            reject(err)
        })

    })

}

export const initDynamicRouter = () => {
    return new Promise((solve, reject) => {
        loadRouters().then(res => {
            console.log('init router')
            window.sessionStorage.setItem('routesSetting', JSON.stringify(res))
            let routes = filterAsyncRouter(res)
            if (routes) {
                routes.forEach((routerItem) => {
                    router.addRoute(routerItem)
                })
            }

            solve(routes)

        }).catch(err => {
            reject(err)

        })
    })

}

/**
 * rebuildDynamicRouter
 * rebuild dynamic router by router setting
 * @param setting
 * @returns {null|*} : return routers
 */
export const rebuildDynamicRouter = (setting) => {
    if (!setting) return null
    let routeList
    if (setting) {
        setting = JSON.parse(setting)
        routeList = filterAsyncRouter(setting)
        routeList.forEach((routerItem) => {
            router.addRoute(routerItem)
        })
    }
    return routeList
}


// routes 动态路由: 刷新页面时候，routes无值，需要通过sessionStorage重建动态路由。
let routes

// load routes from sessionStorage: 通过sessionStorage重建动态路由
let routesSetting = window.sessionStorage.getItem('routesSetting')
routes = rebuildDynamicRouter(routesSetting)


const resetLogin = () => {
    routes = []
    Cookies.set(Setting.cookieTokenName, '')
}

/**
 * 路由导航前监测：
 * 1- 如果白名单，直接进入
 * 2- 如果登录
 *    2.1- 如果动态路由存在， 直接进入导航
 *    2.2- 如果不存在，通过后台访问，初始化路由。如果初始化失败，进入登录。如果成功，进入导航
 * 3- 如果未登录： 进入登录
 */
router.beforeEach(function (to, from, next) {

    if (passRoutes.indexOf(to.path) >= 0) { // 白名单
        next()
    } else {

        // 本地登录状态：
        const token = Cookies.get(Setting.cookieTokenName)

        if (token) { // 本地登录状态

            const userInfo = getUserInfo()
            if (userInfo) {
                store.commit('set_userInfo', userInfo)
            }
            console.log(routes)
            if (routes) {// 动态路由，存在
                console.log('route 1')
                next()
            } else { // 动态路由不存在，需要通过后台返回数据，初始化路由
                console.log('route 2')
                initDynamicRouter().then((res) => {
                    routes = res
                    console.log(router.getRoutes())
                    next()
                }).catch(err => {// 动态路由初始化失败：空登录装填，进入登录
                    console.log('route 3')
                    console.log(err)
                    // routes =[]
                    // Cookies.set(Setting.cookieTokenName, '')
                    resetLogin()
                    next('/login')
                })
            }

        } else { // 本地未登录：清空登录装填，进入登录
            console.log('route 4')
            resetLogin()
            next('/login')
        }
    }


})
