import {createApp} from 'vue'
import App from './App.vue'
import ElementPlus from 'element-plus';
import 'element-plus/theme-chalk/index.css';
import locale from 'element-plus/lib/locale/lang/zh-cn'
import {router} from "@/router/routers";
import {store} from '@/store/index'
import '@/assets/styles/icon.css'
import '@/assets/styles/main.css'

// svg icons:
import '@/assets/icons/index.js'
import SvgIcon from '@/components/common/svgIcon/index.vue'


const app = createApp(App).use(ElementPlus, {locale})
app.use(router)
app.use(store)
app.mount('#app')
app.component('svg-icon', SvgIcon)


app.config.warnHandler = () => null
