import axios from 'axios'
import Setting from '@/settings'
import {getEnv} from '@/utils/env'
import {router} from "@/router/routers";

axios.defaults.withCredentials = true
axios.defaults.crossDomain = true

// 创建axios实例
const service = axios.create({
    baseURL: process.env.NODE_ENV === 'production' ? getEnv('VUE_APP_BASE_API') : '/', // api 的 base_url
    timeout: Setting.request.timeout // 请求超时时间
})

// request拦截器
service.interceptors.request.use(
    config => {
        config.headers['Content-Type'] = config.contentType || Setting.request.contentType || 'application/json'
        // console.log('contentType')
        // console.log(config)
        // POST: if no data but exists params, then use params as data
        if ((config.method.toString().toUpperCase() === 'POST' || config.method.toString().toUpperCase() === 'PUT') && !config.data && config.params) {
            config.data = Object.assign({}, config.params)
            delete config.params
        }
        if (config.method.toString().toUpperCase() === 'GET' && config.url != 'files' && (!config.params || !config.params.pageSize)) {// if GET not provide pageSize, then set 9999
            config.params = config.params || {}
            config.params.pageSize = 9999
        }
        return config
    },
    error => {
        Promise.reject(error)
    }
)

// response 拦截器
service.interceptors.response.use(
    response => {
        // console.log('axios response:')
        // console.log(response)
        return response.data
    },
    error => {
        console.log('axios response ERROR:')
        console.log(error)
        if (error.toString().indexOf('code 401') >= 0 || error.toString().indexOf('code 403') >= 0) {
            console.log('need login')
            router.push('/login')
        }
        return Promise.reject(error)
    }
)
export default service
