import request from "@/utils/req";
import Cookies from "js-cookie";
import Setting from '@/settings'
import {initDynamicRouter} from "@/router/routers";
import {store} from '@/store/index'
import {router} from '@/router/routers'
// import {GF} from "@/utils/common";

export const prepareLogin = () => {

    Cookies.set('token', '')
    // Cookies.remove('token')
    const lastLogin = window.localStorage.getItem('lastLoginId') || ''
    return {
        lastLogin
    }
}

/**
 * login
 * @param username
 * @param password
 * @param vueApp : vue app instance , [this]
 * @returns {Promise<unknown>}
 */
export const login = ({username, password}) => {
    return new Promise((resolve, reject) => {
        request({
            url: 'auth/login',
            method: 'POST',
            data: {
                username,
                password
            }
        }).then(res => {
            if (res.code == 0) {
                const token = res.data
                Cookies.set(Setting.cookieTokenName, token)
                window.localStorage.setItem(Setting.cookieTokenName, token)
                store.commit('set_token', token)

                window.localStorage.setItem('lastLoginId', username)
                console.log('login process1')

                window.localStorage.removeItem('currentMenu')

                saveUserInfo()

                initDynamicRouter().then(() => {
                    router.push('/')
                }).catch(err => {
                    console.log(err)
                    router.push('/')
                    // router.push('/login')
                })

                /*// datafor login:
                DataForLogin().then(() => {
                    // console.log(dataForRes)
                }).catch(err => {
                    console.log(err)
                })*/

                /*GF.loadCategoryDataIntoLocal()
                GF.loadIndexBaseIntoLocal()
                GF.loadTopSpaceNodesIntoLocal()
                GF.loadSamplePeriodMinMaxIntoLocal()
                GF.loadUnitDataIntoLocal()*/

                resolve(token)
            } else {
                console.log('error')
                reject(new Error('Login Failed'))
            }

        }).catch((err) => {
            reject(err)
        })
    })
}

export const logout = (vueApp) => {
    return new Promise((resolve, reject) => {
        request({
            url: 'auth/logout',
            method: 'GET',
        }).then(res => {
            if (res.code == 0) {
                Cookies.remove('app_token')
                window.localStorage.removeItem(Setting.cookieTokenName)
                window.localStorage.removeItem(Setting.cookieUserInfoName)
                if (vueApp) {
                    vueApp.$store.commit('set_token', '')
                    vueApp.$router.push('/login')
                }
                // DataForLogOut()
                resolve()
            } else {
                console.log('Logout Error: Code=' + res.code)
                Cookies.remove('app_token')
                window.localStorage.removeItem(Setting.cookieTokenName)
                if (vueApp) {
                    vueApp.$store.commit('set_token', '')
                    vueApp.$router.push('/login')
                }
                reject(new Error('Logout Failed'))

            }

        }).catch(() => {
            Cookies.remove('app_token')
            window.localStorage.removeItem(Setting.cookieTokenName)
            if (vueApp) {
                vueApp.$store.commit('set_token', '')
                vueApp.$router.push('/login')
            }
            reject(new Error('Logout Failed'))

        })
    })
}


export const saveUserInfo = () => {
    return new Promise((resolve, reject) => {
        request({
            url: 'auth/account',
            method: 'get'
        }).then(res => {
            console.log(res)
            if (res.code == 0) {
                console.log(res.data)
                store.commit('set_userInfo', res.data)
                console.log(Setting.cookieUserInfoName)
                window.localStorage.setItem(Setting.cookieUserInfoName, JSON.stringify(res.data))
                resolve(res.data)
            }
        }).catch(err => {
            reject(err)
        })
    })


}

/**
 * getUserInfo
 * get current user info data.
 * @returns {null|any}
 */
export const getUserInfo = () => {
    let userInfo = window.localStorage.getItem(Setting.cookieUserInfoName)
    if (userInfo) {
        try {
            userInfo = JSON.parse(userInfo)
            return userInfo
        } catch (e) {
            console.log(e)
            return null
        }
    }
    return null
}

/**
 * getAuth
 * get authorized functionId list of current user
 * @returns {*|*[]}
 */
export const getAuth = () => {
    let userInfo = getUserInfo()
    return userInfo ? userInfo.authorities : []
}

/**
 * checkAuth
 * check if current functionId is authorized to current user.
 * @param functionId
 * @returns {boolean}
 */
export const checkAuth = (functionId) => {
    return !functionId || (getUserInfo() && getUserInfo().isSuper == 1 ? true : getAuth().indexOf(functionId) >= 0)
}


export const resetPassword = (userId) => {
    return new Promise((resolve, reject) => {
        request({
            url: 'api/miniapp/orm/user/password/reset/' + userId,
            method: 'POST'
        }).then(res => {
            console.log(res)
            if (res.code == 0) {
                resolve(res.data)
            } else {
                reject(new Error('reset password failed'))
            }
        }).catch(err => {
            reject(err)
        })
    })
}


export const DataForLogin = (username, password) => {
    username = username || 'admin'
    password = password || 'password'
    return new Promise((resolve, reject) => {
        // request({
        //     url: 'datafor/j_spring_security_check',
        //     method: 'POST',
        //     contentType: "application/x-www-form-urlencoded",
        //     withCredentials: true,
        //     crossDomain: true,
        //     dataType: "text",
        //     data:{
        //         j_username: username,
        //         j_password: password
        //     }
        // }).then(res=>{
        //     console.log(res)
        //     resolve(res)
        // }).catch(err=>{
        //     console.log(err)
        //     reject(err)
        // })
        request({
            url: 'datafor/Logout',
            withCredentials: true,
            crossDomain: true,
            method: 'GET'
        }).then(() => {
            console.log('datafor LOGOUT OK')
            // console.log(res)
            return request({
                url: 'datafor/j_spring_security_check',
                method: 'POST',
                contentType: "application/x-www-form-urlencoded",
                withCredentials: true,
                crossDomain: true,
                dataType: "text",
                data: "j_username=" + username + "&j_password=" + password
            })
        }).then(res => {
            console.log('datafor LOGIN OK')
            console.log(res)
            resolve(res)
        }).catch(err => {
            console.log('datafor LOGIN ERROR')
            console.log(err)
            reject(err)
        })
    })
}

export const DataForLogOut = () => {
    return new Promise((resolve, reject) => {
        request({
            url: 'datafor/Logout',
            withCredentials: true,
            crossDomain: true,
            method: 'GET'
        }).then(() => {
            console.log('datafor LOGOUT OK')
            // console.log(res)
            resolve()
        }).catch(err => {
            console.log('datafor LOGOUT ERROR')
            console.log(err)
            reject(err)
        })
    })
}

