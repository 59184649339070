<template>
  <div class="main-body">
    <PageHead/>
    <div class="content-part">
      <div class="left-navi-part">
        <LeftNavi></LeftNavi>
      </div>
      <div class="right-content-part">
        <router-view></router-view>
      </div>
    </div>
  </div>
</template>

<script>
import LeftNavi from '@/views/basic/leftNavi'
import PageHead from '@/views/basic/pageHead'

export default {
  name: 'mainLayout',
  components: {
    LeftNavi,
    PageHead
  }
}
</script>

<style scoped>

</style>
