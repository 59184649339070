module.exports = {
    /**
     * @description 网站标题
     */
    title: '日立园区综合能源管理系统',
    /**
     * @description token在cookie中保存时候的键名
     */
    cookieTokenName: 'app_token',

    /**
     * cookieUserInfoName
     */
    cookieUserInfoName: 'user_info',
    /**
     * @description 记住密码状态下的token在Cookie中存储的天数，默认1天
     */
    tokenCookieExpires: 1,
    /**
     * @description 记住密码状态下的密码在Cookie中存储的天数，默认1天s
     */
    passwordCookieExpires: 1,
    /**
     * @description 请求超时时间，毫秒（默认2分钟）
     */





    request: {
        contentType: 'application/json',
        timeout: 1200000,
    }
}
