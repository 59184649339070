import {createStore} from 'vuex'

export const store = createStore({
    state: {
        token: '',
        routers: [],
        userInfo: {},
        currentMenu: null
    },
    mutations: {
        set_token(state, newVal) {
            state.token = newVal
        },
        set_routers(state, newVal) {
            state.token = newVal
        },
        set_userInfo(state, newVal) {
            state.userInfo = newVal
        },
        set_currentMenu(state, newVal) {
            state.currentMenu = newVal
        }
    },
    getters: {
        token: (state) => {
            return state.token
        },
        routers: (state) => {
            return state.routers
        },
        userInfo: (state) => {
            return state.userInfo
        },
        currentMenu: (state) => {
            return state.currentMenu
        },
    },
    actions: {}

})
