<template>
  <div class="page-head">
    <div class="inner-img">
      <span class="head-left">
        <span class="top-logo"></span>
        <span class="title">临床试验小程序管理系统</span>
      </span>
      <span class="head-right-tools">
<!--        <current_time_view/>-->
        <user_info_top/>
        <el-dropdown trigger="click">
          <span class="el-dropdown-link" title="设定">
           <el-icon><setting/></el-icon>
          </span>
          <template #dropdown>
            <el-dropdown-menu>
              <el-dropdown-item><a href="/#/user/userinfo" target="_top">个人中心</a></el-dropdown-item>
               <el-dropdown-item><a target="_top" v-if="GF.auth('setting', 'query')" @click="showSystemSetting">系统设置</a></el-dropdown-item>
            </el-dropdown-menu>
          </template>
        </el-dropdown>
      </span>


    </div>
    <el-drawer
        title="系统设置"
        v-model="isShowSetting"
        :append-to-body="true"
    >
      <el-form ref="form" :model="formData" label-width="120px">
        <el-form-item v-for="item in settingList" :label="item.name+':'" :key="item.__key+'_inputter'">
          <el-input-number v-if="item.type==='number'" v-model="formData[item.__key]"/>
          <el-input v-else v-model="formData[item.__key]"/>
          <span>&nbsp; {{ item.unit || '' }}</span>
        </el-form-item>
        <el-form-item>
          <el-button :loading="isUpdating" type="primary" @click="submitForm">保存</el-button>
          <el-button @click="reset">重置</el-button>
        </el-form-item>
      </el-form>

    </el-drawer>
  </div>

</template>

<script>
// import current_time_view from "@/components/common/current_time_view";
import user_info_top from "@/components/common/user_info_top";
import {Setting} from "@element-plus/icons-vue"
import {GF} from "@/utils/common"
import req from "@/utils/req";

export default {
  name: "pageHead",
  components: {
    // current_time_view,
    user_info_top,
    Setting
  },
  data() {
    return {
      GF,
      isShowSetting: false,
      formData: {},
      settingData: null,
      settingId: null,
      settingTimestamp: null,
      settingList: [],
      settingAPIUrl: 'api/miniapp/orm/setting',
      isUpdating: false
    }
  },
  mounted() {
    this.loadSystemSetting()
  },
  methods: {
    showSystemSetting() {
      this.isShowSetting = true
    },
    loadSystemSetting() {
      req({
        url: this.settingAPIUrl,
        method: 'GET',
        params: {
          pageSize: 9999
        }
      }).then(res => {
        // console.log(res)
        if (res.code == 0 && res.data?.data && res.data.data[0] && res.data.data[0].setting) {
          this.settingData = res.data.data[0].setting
          this.settingId = res.data.data[0].id
          this.settingTimestamp = res.data.data[0].timestamp

          this.settingList = []

          for (let key in this.settingData) {
            if (this.settingData[key]) {
              let item = Object.assign({}, this.settingData[key], {__key: key})
              this.settingList.push(item)

            }
          }
          this.settingList.sort((a, b) => a.index - b.index)

          this.saveSettingIntoLocalstorage()

          this.settingList.forEach(it => {
            this.formData[it.__key] = it.value
          })
        }
      })
    },
    saveSettingIntoLocalstorage() {
      window.localStorage.setItem('settings', JSON.stringify(this.settingData))
    },
    submitForm() {
      this.$refs['form'].validate((valid) => {
        if (valid) {

          // console.log(this.formData)

          for (let key in this.formData) {
            let settingItem = this.settingList.find(it => it.__key === key)
            let savingItem = Object.assign({}, settingItem)
            delete savingItem.__key
            savingItem.value = this.formData[key]

            this.settingData[key] = savingItem

          }

          this.isUpdating = true

          req({
            url: this.settingAPIUrl,
            method: 'PUT',
            data: {
              id: this.settingId,
              timestamp: this.settingTimestamp,
              setting: this.settingData
            }
          }).then(res => {
            this.isUpdating = false
            if (res.code == 0) {
              this.$message.success('系统设定保存成功')

              this.saveSettingIntoLocalstorage()

              location.reload()

            } else {
              this.$message.error('系统设定保存失败！信息：' + res.message)
            }
          }).catch(err => {
            this.isUpdating = false
            this.$message.error('系统设定保存出错：' + err.toString())
          })


        } else {
          this.$message.error('表单填写有误，请检查后重新提交保存！')
          return false;
        }
      });
    },
    /*submitForm() {
      this.$refs['form'].validate((valid) => {
        console.log(valid)
        if (valid) {

          console.log(this.formData)

          for (let key in this.formData) {
            let settingItem = this.settingList.find(it => it.key === key)

            this.settingData[key] = {
              name: settingItem.name,
              type: settingItem.type,
              value: this.formData[key]
            }
          }

          console.log({
            url: this.settingAPIUrl,
            method: 'PUT',
            data: {
              id: this.settingId,
              timestamp: this.settingTimestamp,
              setting: this.settingData
            }
          })

          this.isUpdating = true

          req({
            url: this.settingAPIUrl,
            method: 'PUT',
            data: {
              id: this.settingId,
              timestamp: this.settingTimestamp,
              setting: this.settingData
            }
          }).then(res => {
            this.isUpdating = false
            if (res.code == 0) {
              this.$message.success('系统设定保存成功')

              this.saveSettingIntoLocalstorage()

              location.reload()

            } else {
              this.$message.error('系统设定保存失败！信息：' + res.message)
            }
          }).catch(err => {
            this.isUpdating = false
            this.$message.error('系统设定保存出错：' + err.toString())
          })


        } else {
          this.$message.error('表单填写有误，请检查后重新提交保存！')
          return false;
        }
      });
    },*/
    reset() {
      this.loadSystemSetting()
    }
  }

}
</script>

<style scoped>

</style>
