<template>
  <span class="user-info-top">
    <el-image class="avatar" v-if="avatar" :src="url" fit="fill"></el-image>
    <el-icon v-if="!avatar" @click="goUserInfo"><User/></el-icon>
    <span class="user-name" @click="goUserInfo">{{ userName }}</span>
    <span class="login-id" @click="goUserInfo">({{ loginId }})</span>
    <a @click="doLogout" title="退出"><el-icon><SwitchButton/></el-icon></a>
  </span>

</template>
<script>
import {logout, getUserInfo} from "@/utils/auth"
import {User, SwitchButton} from "@element-plus/icons-vue"

export default {
  name: "user_info_top",
  components: {
    User,
    SwitchButton
  },
  data() {
    return {
      userName: 'User',
      loginId: 'user',
      avatar: '',
      filePrefix: 'files?path=',
      userInfo: null
    }
  },
  computed: {
    url() {
      return this.filePrefix + this.avatar
    }
  },
  mounted() {
    this.loadInfo()
  },
  methods: {
    goUserInfo() {
      this.$router.push('/user/userinfo')
    },
    loadInfo() {
      this.userInfo = getUserInfo() || {}
      this.userName = this.userInfo.name
      this.loginId = this.userInfo.loginId
      this.avatar = this.userInfo.avatar
      // console.log(this.url)
    },
    doLogout() {
      logout().then(() => {
        this.$router.push('/login')
      }).catch(err => {
        console.log(err)
        this.$message.error('退出登录发生错误！')
        this.$router.push('/login')
      })
    }
  }
}
</script>

<style scoped>

</style>
