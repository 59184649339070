import request from "@/utils/req";
import {store} from '@/store/index'
import {checkAuth, getUserInfo} from "@/utils/auth";


export const loadMenuTree = () => {
    return new Promise((resolve, reject) => {
        /*request({
            url: 'api/miniapp/orm/menu/agg',
            method: 'post',
            data: {
                select: 'id,pid,route,iconUri,name,function.code,type,index,link,invisible,disabled,target'
            }
        }).then(res => {
            if (res.code == 0) {
                const list = res.data.data
                let menuTree = []
                list.sort((a, b) => {
                    return a.index - b.index
                })
                // console.log(list)
                let isSuperuser = store.getters.userInfo.isSuper == '1'
                const resultList = []
                list.forEach(it => {
                    if (!isSuperuser) {
                        if (it.invisible == 1 || it.disabled == 1 || !checkAuth(it.function.code)) {
                            return
                        }
                    }
                    resultList.push(it)
                })
                if (resultList.length > 0) {

                    menuTree = buildTree(resultList, 'id', 'pid', 'children')
                    for (let i = 0; i < menuTree.length; i++) {
                        if (menuTree[i].target == 0 && !menuTree[i].children && !menuTree[i].link) {
                            menuTree.splice(i, 1)
                            i--
                        }
                    }

                    // console.log(menuTree)
                }
                resolve(menuTree)
            } else {
                console.log('Failed Get Menu')
                reject(new Error('Failed Get Menu'))
            }


        }).catch(err => {
            reject(err)
        })*/
        request({
            url: '/auth/account/menu',
            method: 'get'
        }).then(res => {
            if (res.code == 0) {
                const list = res.data.data
                let menuTree = []
                list.sort((a, b) => {
                    return a.index - b.index
                })
                // console.log(list)
                let isSuperuser = store.getters.userInfo.isSuper == '1'
                const resultList = []
                list.forEach(it => {
                    if (!isSuperuser) {
                        if (it.invisible == 1 || it.disabled == 1 || !checkAuth(it.function.code)) {
                            return
                        }
                    }
                    resultList.push(it)
                })
                if (resultList.length > 0) {

                    menuTree = buildTree(resultList, 'id', 'pid', 'children')
                    for (let i = 0; i < menuTree.length; i++) {
                        if (menuTree[i].target == 0 && !menuTree[i].children && !menuTree[i].link) {
                            menuTree.splice(i, 1)
                            i--
                        }
                    }

                    // console.log(menuTree)
                }
                resolve(menuTree)
            } else {
                console.log('Failed Get Menu')
                reject(new Error('Failed Get Menu'))
            }


        }).catch(err => {
            reject(err)
        })
    })
}

/**
 * buildTree
 * build tree data by list.
 * list data has id(own id) and pid(parent id) to construct tree data:
 * [
 *   {
 *       id:1,
 *       name: 'a'
 *       children:[
 *           {
 *               id:11,
 *               pid:1,
 *               name: 'aa'
 *           }
 *       ]
 *   }
 * ]
 * @param list
 * @param mainKey
 * @param parentKey
 * @returns {*[]}
 */
export const buildTree = (list, mainKey, parentKey, childrenKey, parentId, hasChildrenKey) => {
    parentId = parentId || null
    mainKey = mainKey || 'id'
    parentKey = parentKey || 'pid'
    childrenKey = childrenKey || 'children'

    // limitPid = limitPid || null

    const treeData = []

    if (!list || list.length <= 0) return []

    list.forEach(item => {
        if (typeof item[parentKey] === 'undefined') item[parentKey] = null
        if (hasChildrenKey) {
            item[hasChildrenKey] = true
        }
        if (item[parentKey] === parentId) {

            if (!treeData.find(it => it.id == item.id)) {
                treeData.push(item)
            }
        }
    })
    // console.log(list)
    // console.log(treeData)
    const setTreeData = (curTree, totalList) => {
        curTree.forEach((item) => {
            const curSub = []
            totalList.forEach(tItem => {

                if (tItem[parentKey] === item[mainKey] && !curSub.find(it => it.id == tItem.id)) {
                    curSub.push(tItem)
                }
            })

            if (curSub.length > 0) {
                item[childrenKey] = curSub

                // item[hasChildrenKey]= true
                setTreeData(curSub, totalList)
            }
        })
    }

    setTreeData(treeData, list)

    // if(limitPid){
    //     console.log(limitPid)
    // }

    // console.log(treeData)
    return treeData
}

/**
 * dateFormat
 * @param fmt
 * @param date
 * @returns {*}
 */
function dateFormat(fmt, date) {
    let ret;
    const opt = {
        "Y+": date.getFullYear().toString(),        // 年
        "m+": (date.getMonth() + 1).toString(),     // 月
        "d+": date.getDate().toString(),            // 日
        "H+": date.getHours().toString(),           // 时
        "M+": date.getMinutes().toString(),         // 分
        "S+": date.getSeconds().toString()          // 秒
        // 有其他格式化字符需求可以继续添加，必须转化成字符串
    }
    for (let k in opt) {
        ret = new RegExp("(" + k + ")").exec(fmt)
        if (ret) {
            fmt = fmt.replace(ret[1], (ret[1].length == 1) ? (opt[k]) : (opt[k].padStart(ret[1].length, "0")))
        }
    }
    return fmt
}

/**
 * dateFormatter
 * @param timeValue {number}: timevalue in number
 * @returns {*}: formatted string like: 2020-02-01
 */
export const dateFormatter = (timeValue) => {
    if (!timeValue) return ''
    const d = new Date(timeValue)
    return dateFormat("YYYY-mm-dd", d)
}

/**
 * datetimeFormatter
 * @param timeValue  {number}: timeValue in number
 * @returns {*} : formatted string like: 2020-02-01 14:05:30
 */
export const datetimeFormatter = (timeValue) => {
    if (!timeValue) return ''
    const d = new Date(timeValue)
    return dateFormat("YYYY-mm-dd HH:MM:SS", d)
}
/**
 * getPostData
 * get post data for insert request.
 * @param formData {object}: form data
 * @param fields {array}: field-name list which will be submitted, others will be ignored. *
 * @param useContentTypeFormData {boolean}: if contentType use FormData. default= false
 * @returns {FormData/Object}
 */
export const getPostData = (formData, fields, useContentTypeFormData) => {
    let data
    useContentTypeFormData = Boolean(useContentTypeFormData)

    if (useContentTypeFormData) {
        data = new FormData()
        const curData = formData
        for (const key in curData) {
            if (fields.indexOf(key) >= 0 && curData[key] !== '' && curData[key] !== null) {
                data.append(key, curData[key])
            }
        }
    } else {
        const curData = JSON.parse(JSON.stringify(formData))
        for (const key in curData) {
            if (fields.indexOf(key) < 0 || curData[key] === '' || curData[key] === null) {
                delete curData[key]
            }
        }
        data = curData
    }
    return data
}

/**
 * getPutData
 * get post data for update
 * @param formData {object}: form data
 * @param fields {array}: field-name list which will be submitted, others will be ignored. *
 * @param id {string}: current id.
 * @param timestamp {timestamp}: current timestamp
 * @param useContentTypeFormData {boolean}: if contentType use FormData. default= false
 * @param idColumn {string}: id key name. DEFAULT= 'id'
 * @returns {null|FormData}
 */
export const getPutData = (formData, fields, id, timestamp, useContentTypeFormData, idColumn) => {
    let data

    useContentTypeFormData = Boolean(useContentTypeFormData)
    idColumn = idColumn || 'id'
    const curData = Object.assign({}, formData)
    if (useContentTypeFormData) {
        data = new FormData()
        for (const key in curData) {
            if (fields.indexOf(key) >= 0) {
                data.append(key, curData[key])
            }
        }
        if (id) data.append(idColumn, id)
        if (timestamp) data.append('timestamp', timestamp)
    } else {
        for (const key in curData) {
            if (fields.indexOf(key) < 0) {
                delete curData[key]
            }
        }
        data = curData
        if (id) data[idColumn] = id
        if (timestamp) data.timestamp = timestamp
    }
    return data
}

/**
 * getRequestData
 * get request data for loading data by api.
 * if method= GET: then just gather the params and return it.
 * if method=POST: then parse the params for api special format and return it.
 * @param method {string}: GET/POST
 * @param currentParams {object}: current params in object format
 * @param fixedParams {object}: fixed params in object format. Aware: fixedParams will cover the currentParams data.
 * @param listSort {string}: sort string, like: 'timestamp DESC'
 * @param paging {object}: .index -- page index number, start with 0.
 *                         .size  -- page size. default: 20
 * @param columnSelected {Array/String}: selected column (when POST) names in array or string (split by ,)
 * @param directParams {object}: params that directly added without any parse (when POST). Aware: directParams will cover fixedParams.
 * @returns {{}}
 */
export const getRequestData = (method, currentParams, fixedParams, listSort, paging, columnSelected, directParams, idKey) => {

    method = method || 'get'  // get/post
    let result = transferApiParams({
        selectedColumnIds: columnSelected,
        fixedParams: fixedParams,
        currentParams: currentParams,
        directParams: directParams,
        orderBy: listSort,
        groupBy: null,
        having: null,
        paging: paging,
        idKey: idKey,
        apiType: method.toUpperCase() == 'GET' ? 0 : 1,
        isTransferArrayToString: true,
        isTransferObjectToString: true,
    })
    return result
    /*idKey = idKey || 'id'
    let params = {}
    currentParams = currentParams || {}
    fixedParams = fixedParams || {}
    params = Object.assign(params, currentParams)
    params = Object.assign(params, JSON.parse(JSON.stringify(fixedParams)))
    let result ={}
    if(method.toLowerCase()==='get') {// if get: simple params
        result = params
        // if value is array, then join into string separated by ','
        for(let key in result){
            if(Array.isArray(result[key])){
                result[key] = result[key].join(',')
            }
        }
        if(paging){
            result.pageIndex=paging.index || 0
            result.pageSize=paging.size || 20
        }
    }else{// if post: need parse params
        let whereData={}
        let valueParse = (setItem, v)=>{
            if(Array.isArray(v)){

                setItem['in'] = v

                // if(v.length===2){
                //
                //     setItem['gt'] = v[0]
                //     setItem['lt'] = v[1]
                //     // setItem={
                //     //     "gt": v[0],
                //     //     "lt": v[1]
                //     // }
                // }
            }else{
                // setItem={"eq":v}
                if(v.toString().indexOf('*')>=0){
                    setItem['lk']= v.replace(/\*!/g,'%')
                }else if(v.toString().indexOf(',')>0){
                    setItem['in'] = v.split(',')
                }else{
                    setItem['eq'] = v
                }

            }
            return setItem
        }
        for(let key in params){
            if(params[key]==='' || params[key]===null || typeof params[key]==='undefined' ) continue
            //support '.' sub keys
            if(key.indexOf('.')>0){
                const keys=key.split('.')
                let keyResult ={}
                let cur =keyResult
                keys.forEach(k=>{
                    cur[k]={}
                    cur=cur[k]
                })
                // cur['eq']= params[key]
                valueParse(cur, params[key])
                whereData= Object.assign(whereData, keyResult)

            }else{// direct key:
                // whereData[key]={
                //     "eq": params[key]
                // }
                if(key==='withParent' || key==='withChildren'){
                    result[key] = params[key]
                }else{
                    whereData[key]= valueParse({}, params[key])
                }

            }
        }
        result['where'] = whereData
        result['select'] = ''
        if(columnSelected){
            result['select']= Array.isArray(columnSelected) ? columnSelected.join(',') : columnSelected
        }
        result['select']+= (result['select'] ? ',':'') + idKey+',timestamp'

        if(paging){
            result.paging={
                index: paging.index || 0,
                size: paging.size || 20
            }
        }

    }

    if (listSort) {
        result.orderBy = listSort
    }

    if(directParams && Object.keys(directParams).length>0){
        result =Object.assign(result, directParams)
    }

    return result*/
}// -/

/**
 * getRequestDataFull
 * get request data for loading data by api.
 * if method= GET: then just gather the params and return it.
 * if method=POST: then parse the params for api special format and return it.
 * @param method {string}: GET/POST
 * @param currentParams {object}: current params in object format
 * @param fixedParams {object}: fixed params in object format. Aware: fixedParams will cover the currentParams data.
 * @param listSort {string}: sort string, like: 'timestamp DESC'
 * @param groupBy {string}: group by string.  separate with comma
 * @param paging {object}: .index -- page index number, start with 0.
 *                         .size  -- page size. default: 20
 * @param columnSelected {Array/String}: selected column (when POST) names in array or string (split by ,)
 * @param directParams {object}: params that directly added without any parse (when POST). Aware: directParams will cover fixedParams.
 * @param idKey {string}: id key name, default='id'
 * @param isAutoSelectIdTimestamp {bool}: true[default]- automatically add id and timestamp to select.
 * @returns {{}}
 */
export const getRequestDataFull = (method, currentParams, fixedParams, listSort, groupBy, paging, columnSelected, directParams, idKey, isAutoSelectIdTimestamp) => {

    // let result = getRequestData(method,currentParams,fixedParams, listSort,paging,columnSelected, directParams,idKey)
    // if(groupBy && typeof groupBy ==='string'){
    //     result.groupBy = groupBy
    // }
    isAutoSelectIdTimestamp = typeof isAutoSelectIdTimestamp === 'undefined' ? true : Boolean(isAutoSelectIdTimestamp)
    method = method || 'get'  // get/post
    let result = transferApiParams({
        selectedColumnIds: columnSelected,
        fixedParams: fixedParams,
        currentParams: currentParams,
        directParams: directParams,
        orderBy: listSort,
        groupBy: groupBy,
        having: null,
        paging: paging,
        idKey: idKey,
        apiType: method.toUpperCase() == 'GET' ? 0 : 1,
        isTransferArrayToString: true,
        isTransferObjectToString: true,
        isAutoSelectId: isAutoSelectIdTimestamp,
        isAutoSelectTimestamp: isAutoSelectIdTimestamp
    })

    return result

}// -/


/**
 * transferApiParams
 * @param apiType {number/string} : 0 - traditional api type, like {name: "AAAA"} ;  1- KBITC api type, like {name:{eq: "AAAA"}}
 * @param selectedColumnIds {string/Array}: selected columns list. like 'name,title,time' . *
 *                                          [idKey] and timestamp fields will be added automatically
 * @param fixedParams
 * @param currentParams
 * @param directParams
 * @param orderBy
 * @param groupBy
 * @param having
 * @param paging
 * @param idKey
 * @param isTransferArrayToString
 * @param isTransferObjectToString
 * @param isAutoSelectId
 * @param isAutoSelectTimestamp
 * @returns {{}}
 */
export const transferApiParams = ({
                                      apiType,
                                      selectedColumnIds,
                                      fixedParams,
                                      currentParams,
                                      directParams,
                                      orderBy,
                                      groupBy,
                                      having,
                                      paging,
                                      idKey,
                                      isTransferArrayToString,
                                      isTransferObjectToString,
                                      isTransferNull,
                                      isAutoSelectId,
                                      isAutoSelectTimestamp
                                  }) => {

    apiType = apiType || 0
    idKey = idKey || 'id'
    let params = {}

    isTransferArrayToString = typeof isTransferArrayToString == 'undefined' ? true : Boolean(isTransferArrayToString)
    isTransferObjectToString = typeof isTransferObjectToString == 'undefined' ? true : Boolean(isTransferObjectToString)
    isTransferNull = typeof isTransferNull == 'undefined' ? false : Boolean(isTransferNull)
    isAutoSelectId = typeof isAutoSelectId == 'undefined' ? true : Boolean(isAutoSelectId)
    isAutoSelectTimestamp = typeof isAutoSelectTimestamp == 'undefined' ? true : Boolean(isAutoSelectTimestamp)

    currentParams = currentParams || {}
    fixedParams = fixedParams || {}
    params = Object.assign(params, JSON.parse(JSON.stringify(currentParams)))
    params = Object.assign(params, JSON.parse(JSON.stringify(fixedParams)))

    // console.log('fixedParams::')
    // console.log(fixedParams)
    // console.log(currentParams)
    // console.log('params===>')
    // console.log(params)

    let result = {}
    if (apiType.toString() === '0') {// if apiType ==0: traditional api type
        result = params

        for (let key in result) {
            if (result[key] === null) {
                if (!isTransferNull) delete result[key]
                continue
            }
            if (typeof result[key] === 'object') {
                if (Array.isArray(result[key])) {
                    if (isTransferArrayToString) result[key] = result[key].join(',')
                } else {
                    if (isTransferObjectToString) result[key] = JSON.stringify(result[key])
                }
            }
        }
        if (paging) {
            result.pageIndex = paging.index || 0
            result.pageSize = paging.size || 20
        }
        if (orderBy) {
            result.orderBy = orderBy
        }
        if (groupBy) {
            result.groupBy = groupBy
        }
        if (having) {
            result.having = having
        }


    } else if (apiType.toString() === '1') {// if apiType ==1: KBITC type api params, like  { name:{eq: "AAA"}}
        let whereData = {}

        // value parser func[recursive]----------------
        let valueParse = (setItem, v) => {
            if (Array.isArray(v)) {
                setItem['in'] = v
            } else {
                if (v.toString().indexOf('*') >= 0) {
                    setItem['lk'] = v.replace(/\*/g, '%')
                } else if (v.toString().indexOf(',') > 0) {
                    setItem['in'] = v.split(',')
                } else {
                    setItem['eq'] = v
                }
            }
            return setItem
        }
        // ---------------------------------------------
        for (let key in params) {
            if (params[key] === '' || params[key] === null || typeof params[key] === 'undefined') continue
            //support '.' sub keys
            if (key.indexOf('.') > 0) {
                const keys = key.split('.')
                let keyResult = {}
                let cur = keyResult
                keys.forEach(k => {
                    cur[k] = {}
                    cur = cur[k]
                })
                valueParse(cur, params[key])
                whereData = Object.assign(whereData, keyResult)

            } else {// direct key:
                if (key === 'withParent' || key === 'withChildren') {
                    result[key] = params[key]
                } else {
                    whereData[key] = valueParse({}, params[key])
                }

            }
        }
        result['where'] = whereData
        result['select'] = ''
        if (selectedColumnIds) {
            result['select'] = Array.isArray(selectedColumnIds) ? selectedColumnIds.join(',') : selectedColumnIds
        }
        // result['select']+= (result['select'] ? ',':'')  // + idKey+',timestamp'

        if (isAutoSelectId) result['select'] += (result['select'] ? ',' : '') + idKey
        if (isAutoSelectTimestamp) result['select'] += (result['select'] ? ',' : '') + 'timestamp'

        if (paging) {
            result.paging = {
                index: paging.index || 0,
                size: paging.size || 20
            }
        }
        if (orderBy) {
            result.orderBy = orderBy
        }
        if (groupBy) {
            result.groupBy = groupBy
        }
        if (having) {
            result.having = having
        }
    }

    if (directParams && Object.keys(directParams).length > 0) {
        result = Object.assign(result, directParams)

    }

    return result
}// -/

/**
 * getSearchWhere
 * get where condition object from search form
 * @param searchFormData
 */
export const getSearchWhere = (searchFormData, fieldSetting, isEmptyEmit) => {
    isEmptyEmit = Boolean(isEmptyEmit)
    // console.log(isEmptyEmit)
    let dataEmit
    let result = {}
    result['_dataType'] = 'FORMATTED'
    if (searchFormData) {
        dataEmit = Object.assign({}, searchFormData)
        for (let key in dataEmit) {

            let currentValue = dataEmit[key]
            if (currentValue === null || typeof currentValue === 'undefined' || currentValue.toString().trim() === '') { // if current value is empty
                if (!isEmptyEmit) {
                    continue
                } else {
                    currentValue = null
                }
            }


            // if string type, then trim
            if (typeof currentValue === 'string') currentValue = currentValue.toString().trim()


            // get compare Mode in fields data
            let found = fieldSetting.find(it => it.id == key)


            if (!found) {
                // if xxx.xxx key
                if (typeof currentValue == 'object' && !Array.isArray(currentValue)) {
                    for (let subKey in currentValue) {

                        found = fieldSetting.find(it => it.id == key + '.' + subKey)
                        if (found && currentValue[subKey] !== '') {
                            let compareMode = found.compareMode || 'like'
                            let resultItem = parseSearchItem(compareMode, currentValue[subKey])
                            GF.setKeyVal(result, key + '.' + subKey, resultItem)
                        }


                    }
                }


            } else if (found) {

                let compareMode = found.compareMode || 'like'
                let resultItem = parseSearchItem(compareMode, currentValue)
                result[key] = resultItem
                // GF.setKeyVal(result,key,resultItem)

            }


            // let compareMode = found.compareMode || 'like'
            // let valueStr,compareMarkLeft,compareMarkRight, valueArray
            // switch (compareMode) {
            //     case 'eq':
            //         result[key] = {}
            //         result[key]['eq'] = currentValue
            //         break
            //     case 'like':
            //         result[key] = {}
            //         valueStr = currentValue.toString().trim()
            //         if (valueStr.indexOf('*') >= 0 || valueStr.indexOf('%') >= 0) {
            //             result[key]['lk'] = currentValue
            //         } else {
            //             result[key]['eq'] = currentValue
            //         }
            //         break
            //     case 'likeOnly':
            //         result[key] = {}
            //         result[key]['lk'] = '%'+currentValue+'%'
            //         break
            //     case 'lt':
            //     case 'gt':
            //     case 'le':
            //     case 'ge':
            //         result[key] = {}
            //         result[key][compareMode] = currentValue
            //         break
            //     case 'in':
            //
            //         if(Array.isArray(currentValue)){
            //             result[key]={}
            //             result[key]['in'] = currentValue
            //         }else if(typeof currentValue==='string'){
            //             result[key]={}
            //             result[key]['in'] =  currentValue.toString().split(',')
            //         }
            //         break
            //     case 'between-ee':
            //     case 'between-tt':
            //     case 'between-et':
            //     case 'between-te':
            //         result[key] = {}
            //         compareMarkLeft= compareMode.substr(8,1)
            //         compareMarkRight= compareMode.substr(9,1)
            //         if(Array.isArray(currentValue)){
            //             valueArray= currentValue
            //         }else if(typeof currentValue ==='string'){
            //             valueArray= currentValue.split(',')
            //         }
            //         if(valueArray.length>0) result[key]['g'+compareMarkLeft]= valueArray[0]
            //         if(valueArray.length>1) result[key]['l'+compareMarkRight]= valueArray[1]
            //         break
            // }


            /*if (dataEmit[key] !== null && typeof dataEmit[key] !== 'undefined' && dataEmit[key].toString().trim() !== '') { // input item is not empty:
                // if string type, then trim
                if (typeof dataEmit[key] === 'string') dataEmit[key] = dataEmit[key].toString().trim()


                // get compare Mode in fields data
                let found = fieldSetting.find(it => it.id == key)
                let compareMode = found.compareMode || 'like'


                let valueStr,compareMarkLeft,compareMarkRight, valueArray
                switch (compareMode) {
                    case 'eq':
                        result[key] = {}
                        result[key]['eq'] = dataEmit[key]
                        break
                    case 'like':
                        result[key] = {}
                        valueStr = dataEmit[key].toString().trim()
                        if (valueStr.indexOf('*') >= 0 || valueStr.indexOf('%') >= 0) {
                            result[key]['lk'] = dataEmit[key]
                        } else {
                            result[key]['eq'] = dataEmit[key]
                        }
                        break
                    case 'likeOnly':
                        result[key] = {}
                        result[key]['lk'] = '%'+dataEmit[key]+'%'
                        break
                    case 'lt':
                    case 'gt':
                    case 'le':
                    case 'ge':
                        result[key] = {}
                        result[key][compareMode] = dataEmit[key]
                        break
                    case 'in':

                        if(Array.isArray(dataEmit[key])){
                            result[key]={}
                            result[key]['in'] = dataEmit[key]
                        }else if(typeof dataEmit[key]==='string'){
                            result[key]={}
                            result[key]['in'] =  dataEmit[key].toString().split(',')
                        }
                        break
                    case 'between-ee':
                    case 'between-tt':
                    case 'between-et':
                    case 'between-te':
                        result[key] = {}
                        compareMarkLeft= compareMode.substr(8,1)
                        compareMarkRight= compareMode.substr(9,1)
                        if(Array.isArray(dataEmit[key])){
                            valueArray= dataEmit[key]
                        }else{
                            valueArray= dataEmit[key].split(',')
                        }
                        if(valueArray.length>0) result[key]['g'+compareMarkLeft]= valueArray[0]
                        if(valueArray.length>1) result[key]['l'+compareMarkRight]= valueArray[1]
                        break
                }


            }*/
        }
    }
    console.log(result)
    return result
}// -/

function parseSearchItem(compareMode, currentValue) {

    let result = {}
    compareMode = compareMode || 'like'
    let valueStr, compareMarkLeft, compareMarkRight, valueArray
    switch (compareMode) {
        case 'eq':

            result['eq'] = currentValue
            break
        case 'like':

            valueStr = currentValue.toString().trim()
            if (valueStr.indexOf('*') >= 0 || valueStr.indexOf('%') >= 0) {
                result['lk'] = currentValue
            } else {
                result['eq'] = currentValue
            }
            break
        case 'likeOnly':

            result['lk'] = '%' + currentValue.toString().trim() + '%'
            break
        case 'lt':
        case 'gt':
        case 'le':
        case 'ge':

            result[compareMode] = currentValue
            break
        case 'in':

            if (Array.isArray(currentValue)) {

                result['in'] = currentValue
            } else if (typeof currentValue === 'string') {

                result['in'] = currentValue.toString().split(',')
            }
            break
        case 'between-ee':
        case 'between-tt':
        case 'between-et':
        case 'between-te':
            if (currentValue) {
                compareMarkLeft = compareMode.substr(8, 1)
                compareMarkRight = compareMode.substr(9, 1)
                if (Array.isArray(currentValue)) {
                    valueArray = currentValue
                } else if (typeof currentValue === 'string') {
                    valueArray = currentValue.split(',')
                }
                if (valueArray.length > 0) result['g' + compareMarkLeft] = valueArray[0]
                if (valueArray.length > 1) result['l' + compareMarkRight] = valueArray[1]
            } else {
                result = null
            }

            break
    }
    return result
}// -/

/**
 * exportListToFile
 * request export API to do list data exporting (trigger file download).
 * @param requestParams  {object}: request params to api
 * @param exportFileType {string}: 'json' / 'xlsx' / 'csv'
 * @param columns        {array} : [ [column1_Id, column1_Name],[column2_Id, column2_Name],... ...]
 * @param apiUrl         {string}: list requesting api url. with suffix like 'agg' or 'query' or ''. NOT 'export' API url.
 * @param asTree         {bool}  : export as tree.
 * @param listData       {array} : list data. if exist, then give data directly to backend to download.
 * @param moduleName     {string}: module name string, for listData export. tell backend file name prefix string.
 */
export const exportListToFile = ({requestParams, exportFileType, columns, apiUrl, asTree, listData, moduleName}) => {


    exportFileType = exportFileType || 'json'
    asTree = asTree || false
    listData = listData || null
    moduleName = moduleName || 'data'

    const form = document.createElement("form");
    form.style.display = "none";
    if (listData) {
        form.action = 'api/bems/util/export/tree?filetype=' + exportFileType + "&prefix=" + moduleName.substr(0, 1).toUpperCase() + moduleName.substr(1)
    } else {
        form.action = apiUrl + '/export?filetype=' + exportFileType;
    }

    form.method = "post";
    document.body.appendChild(form);


    // handle Alias:
    let needAliasList = []
    let finalColumns = []
    if (requestParams?.select) {
        let columnList = requestParams.select.split(',')
        columnList.forEach(col => {
            col = col.toString().trim()
            if (col.indexOf('.') > 0 && col.indexOf(' ') < 0) {
                finalColumns.push(col + ' ' + col.replace(/\./g, '_'))
                needAliasList.push({id: col, alias: col.replace(/\./g, '_')})
            } else if (col.indexOf('.') > 0 && col.indexOf(' ') > 0) {
                let colParts = col.split(' ')
                finalColumns.push(col)
                needAliasList.push({id: colParts[0], alias: colParts[colParts.length - 1]})
            } else {
                finalColumns.push(col)
            }
        })
        requestParams.select = finalColumns.join(',')
    }
    if (asTree) {
        requestParams.withChildren = true
    }


    let input = document.createElement("input")

    if (listData) { // if has data
        // add data
        input.name = 'data'
        input.value = encodeURIComponent(JSON.stringify(listData))
        form.appendChild(input)

    } else {
        // add criteria
        input.name = 'criteria'
        input.value = encodeURIComponent(JSON.stringify(requestParams))
        form.appendChild(input)
    }


    // add header:
    //     handle alias:
    columns.forEach((col, idx) => {
        let found = needAliasList.find(it => it.id === col[0])
        if (found && found.alias) {
            columns[idx][0] = found.alias
        }
    })
    input = document.createElement("input")
    input.name = 'parameters'
    const headerParam = {
        headers: columns
    }
    input.value = encodeURIComponent(JSON.stringify(headerParam))
    form.appendChild(input)

    // add asTree:
    if (asTree && !listData) {
        input = document.createElement("input")
        input.name = 'asTree'
        input.value = true
        form.appendChild(input)
    }
    form.submit()
    form.remove()

}// -/


export class GF {

    static dustbin(value) {
        this.dust = value
    }

    /**
     * mergeData
     * merge data by key.
     * change original data.
     * e.g.:   {a:1,b:2} , {a:3, c:3}  => {a:3,b:2,c:3}
     * @param dataOri {object}: original data. will be changed.
     * @param newData {object}: new data
     * @returns {*}
     */
    static mergeData(dataOri, newData) {

        if (typeof dataOri !== 'object') return dataOri

        // handle original data keys:
        for (let key in dataOri) {

            if (typeof newData[key] === 'undefined') continue  // if new key value is undefined, then skip current key

            if (typeof dataOri[key] !== 'object') {// original key value is not object:

                if (typeof newData[key] !== 'undefined') dataOri[key] = newData[key]

            } else {// original key value is object:

                if (typeof newData[key] === 'object') {//new key value is object too: recursive-call
                    this.mergeData(dataOri[key], newData[key])
                } else {// new key value is not object, then just cover original value.
                    dataOri[key] = newData[key]
                }
            }
        }
        // append key of newData which not exist in original data.
        let oldKeys = Object.keys(dataOri)
        for (let key in newData) {
            if (oldKeys.indexOf(key) < 0) {
                if (typeof newData[key] !== 'undefined') dataOri[key] = newData[key]
            }
        }
        return dataOri
    }

    // static isIndexTypeConsumption(indexType){
    //     if(indexType){
    //
    //     }
    // }

    static getCarbonUnit() {
        return 'kg'
    }

    static getDefaultCategoryId() {
        return 'fb3dfb41cb75416792771f83da9b1225'
    }

    /**
     * chartDP
     * chart小数点位
     * @returns {number}
     */
    static chartDP() {
        return 2
    }

    /**
     *
     * @param responseData {object}: response data, like {code:0, data:0 ...}
     * @param operationType {string}: operation type: insert/update/delete/select
     * @param customMsgSetting {object}: custom message setting, format like:
     *                                   {
     *                                       'code0data0': '数据发生修改，需要刷新后再试。',
     *                                       'code-1': '数据库出错'
     *                                   }
     * @return {string}
     */
    static getErrorMsg(responseData, operationType, customMsgSetting) {

        operationType = (operationType || 'insert').toString().toLowerCase()

        customMsgSetting = typeof customMsgSetting !== 'object' ? {} : customMsgSetting

        if (!responseData) return ''

        let code, data

        code = responseData.code || null
        data = responseData.data || null

        if (code === null) return ''

        let key = 'code' + code.toString() + (data !== null ? 'data' + String(data) : '')

        let msgSetting = {
            'insert': {
                'code-255': '重复添加了已存在的数据',
                'code-254': '关联数据违反唯一性',
                'code-1': '数据库出错',

            },
            'update': {
                'code0data0': '数据更新是失败，数据已经被他人修改或者数据没有变化。',
                'code-255': '更新导致数据重复',
                'code-254': '关联数据违反唯一性',
                'code-1': '数据库出错',
            },
            'delete': {
                'code0data0': '删除失败，数据已经被修改，需要刷新后再试。',
                'code-254': '当前项已经被引用，无法删除',
                'code-1': '数据库出错',
            },
            'select': {
                'code-1': '数据库出错',
            }

        }

        let currentSetting = msgSetting[operationType]
        currentSetting = Object.assign(currentSetting, customMsgSetting)

        // -255, find duplicate value
        let dupKey = ''
        if (key === 'code-255') {
            let resMsg = responseData.message
            let keyRes = resMsg.match(/\([^)]+\)(?=(\s+already\s+exists))/g)
            if (keyRes?.length > 0) {
                dupKey = keyRes[0]
                return currentSetting[key] + '，' + dupKey + '已存在。'
            }
        }

        return currentSetting[key] || '未知错误'

    }// -/

    static getRequestFailedMsg() {
        return '网络通信异常，请稍后再试！'
    }// -/


    static parseDateUTC(timeString) {

        timeString = timeString.toString().replaceAll('-', '/')
        return new Date(Date.parse(timeString))
    }

    /**
     * isIndexEfficiencyByIndexType
     * indexType is efficiency type of not.
     * @param indexType
     * @returns {boolean} : true - efficiency type; false - not.
     */
    static isIndexEfficiencyByIndexType(indexType) {
        switch (indexType.toString().toUpperCase()) {
            case 'A00':
            case 'B01':
            case 'B22':
                return false
            default:
                return true
        }
    }

    /* static loadIndex(renterId,year,type){
         return new Promise((resolve,reject)=>{

             request({
                 url: 'api/miniapp/orm/spacerenterindex',
                 method: 'GET',
                 params:{
                     renterId: renterId,
                     year: Number(year),
                     type: type,
                     pageSize:1,
                     pageIndex:0

                 }
             }).then(res=>{
                 if(res.code==0){
                     let result={values:[],type:type,renterId:renterId,year:Number(year)}
                     if(res.data?.data?.length>0){
                         let cur = res.data.data[0]
                         if(cur.value?.subValues?.length>0){
                             cur.value.subValues.forEach(it=>{
                                 result.values.push(it.value)
                             })
                         }
                         result.name = cur.name

                     }
                     resolve(result)

                 }else{
                     reject(new Error('Error when loading index'))
                 }
             })

         })

     }*/

    static loadIndexByTime(renterId, type, startTime, endTime, timePeriod, eCategoryId, yearDivisionAmount) {

        yearDivisionAmount = yearDivisionAmount || 12  // division amount of a year.

        return new Promise((resolve, reject) => {

            let yearList = []
            for (let i = startTime.getFullYear(); i <= endTime.getFullYear(); i++) {
                yearList.push(i)
            }

            let whereData = {
                renterId: {eq: renterId},
                year: {"in": yearList},
                type: {eq: type},
            }
            if (typeof eCategoryId === 'string' || typeof eCategoryId === 'number') {
                if (eCategoryId.toString().indexOf(',') > 0) {
                    whereData.index = {categoryId: {in: eCategoryId.toString().split(',')}}
                } else {
                    whereData.index = {categoryId: {eq: eCategoryId}}
                }

            } else if (eCategoryId === null) {
                whereData.index = {categoryId: {nul: true}}
            }


            request({
                url: 'api/miniapp/orm/indexbind/agg',
                method: 'POST',
                params: {
                    select: 'renter.name renterName,renterId,indexId,index.name indexName,index.quota indexQuota,index.subquota indexSubquota,type,year,index.unitId indexUnitId,index.categoryId indexCategoryId',
                    where: whereData,
                    orderBy: 'year'

                }
            }).then(res => {
                if (res.code == 0) {
                    let result = {
                        values: [],
                        type: type,
                        renterId: renterId,
                        nameList: [],
                        name: null,
                        years: [],
                        unitId: null
                    }
                    if (res.data?.data?.length > 0) {


                        let startYear = res.data.data[0].year
                        let endYear = res.data.data[res.data.data.length - 1].year

                        result.startTime = GF.getTimeBegin(startYear)
                        result.unitId = res.data.data[0].indexUnitId


                        for (let curYear = startYear; curYear <= endYear; curYear++) {

                            result.years.push(curYear)

                            let found = res.data.data.find(it => it.year == curYear)

                            if (found) { // if curYear has index values:
                                if (timePeriod == '2') { // monthly:
                                    if (found.indexSubquota?.length > 0) {

                                        for (let j = 0; j < yearDivisionAmount; j++) {
                                            result.values.push(found.indexSubquota.length > j ? found.indexSubquota[j].value : null)
                                        }

                                    }
                                } else if (timePeriod == '1') { // yearly:
                                    result.values.push(found.indexQuota)
                                }
                                result.name = found.indexName
                                result.nameList.push(found.indexName)
                            } else { // if curYear does not have index: fill with NULL
                                if (timePeriod == '2') {
                                    for (let j = 0; j < yearDivisionAmount; j++) {
                                        result.values.push(null)
                                    }
                                } else if (timePeriod == '1') {
                                    result.values.push(null)
                                }
                                result.name = null
                                result.nameList.push(null)
                            }
                        }


                    }
                    console.log('index by time result==========================>>>>>>>>>>>>')
                    console.log(result)

                    resolve(result)

                } else {
                    reject(new Error('Error when loading index'))
                }
            })

        })

    }


    static loadIndex(renterId, year, type) {
        return new Promise((resolve, reject) => {

            request({
                url: 'api/miniapp/orm/indexbind/agg',
                method: 'POST',
                params: {
                    select: 'renter.name renterName,renterId,indexId,index.name indexName,index.quota indexQuota,index.subquota indexSubquota,type,year',
                    where: {
                        renterId: {eq: renterId},
                        year: {eq: Number(year)},
                        type: {eq: type},
                    }

                }
            }).then(res => {
                if (res.code == 0) {
                    let result = {value: 0, values: [], type: type, renterId: renterId, year: Number(year)}
                    if (res.data?.data?.length > 0) {
                        let cur = res.data.data[0]
                        if (cur.indexSubquota?.length > 0) {
                            cur.indexSubquota.forEach(it => {
                                result.values.push(it.value)
                            })
                        }
                        result.value = cur.indexQuota
                        result.name = cur.indexName

                    }
                    resolve(result)

                } else {
                    reject(new Error('Error when loading index'))
                }
            })

        })

    }

    static loadIndexById(indexId) {
        return new Promise((resolve, reject) => {

            request({
                url: 'api/miniapp/orm/index',
                method: 'GET',
                params: {
                    id: indexId,
                    pageSize: 1,
                    pageIndex: 0

                }
            }).then(res => {
                if (res.code == 0) {
                    let result = {values: [], value: 0}
                    if (res.data?.data?.length > 0) {
                        let cur = res.data.data[0]
                        if (cur.quota) {
                            if (cur.value?.subquota?.length > 0) {
                                cur.value.subquota.forEach(it => {
                                    result.values.push(it.value)
                                })
                            }
                            result.value = Number(cur.quota)
                        }
                        result.name = cur.name
                        result.year = Number(cur.year)
                        result.type = cur.subtype


                    }
                    resolve(result)

                } else {
                    reject(new Error('Error when loading index'))
                }
            })

        })

    }

    /*static loadBenchmark(benchmarkId){
        return new Promise((resolve,reject)=>{

            request({
                url: 'api/miniapp/orm/spacerenterbenchmark',
                method: 'GET',
                params:{
                    id:benchmarkId,
                    pageSize:1,
                    pageIndex:0

                }
            }).then(res=>{
                if(res.code==0){
                    let result={values:[]}
                    if(res.data?.data?.length>0){
                        let cur = res.data.data[0]
                        result={values:[],name: cur.name,type:cur.type,renterId:cur.renterId,year:Number(cur.year)}
                        if(cur.value?.subValues?.length>0){
                            cur.value.subValues.forEach(it=>{
                                result.values.push(it.value)
                            })
                        }


                    }
                    resolve(result)

                }else{
                    reject(new Error('Error when loading index'))
                }
            })

        })

    }*/

    static loadBenchmark(benchmarkId, renterId) {
        return new Promise((resolve, reject) => {

            request({
                url: 'api/miniapp/orm/benchmarkbind/agg',
                method: 'POST',
                params: {
                    where: {
                        benchmarkId: {eq: benchmarkId},
                        renterId: {eq: renterId}
                    },
                    select: 'type,benchmarkId,benchmark.name benchmarkName,renterId,type,benchmark.quota benchmarkQuota,benchmark.subquota benchmarkSubquota'

                }
            }).then(res => {
                if (res.code == 0) {
                    let result = {values: []}
                    if (res.data?.data?.length > 0) {
                        let cur = res.data.data[0]
                        result = {value: 0, values: [], name: cur.benchmarkName, type: cur.type, renterId: cur.renterId}
                        if (cur.benchmarkSubquota?.length > 0) {
                            cur.benchmarkSubquota.forEach(it => {
                                result.values.push(it.value)
                            })
                        }
                        result.value = cur.benchmarkQuota

                    }
                    resolve(result)

                } else {
                    reject(new Error('Error when loading index'))
                }
            })

        })

    }

    static loadBenchmarkByTime(benchmarkId, renterId, startTime, endTime, timePeriod, eCategoryId, yearDivisionAmount) {
        yearDivisionAmount = yearDivisionAmount || 12
        return new Promise((resolve, reject) => {

            let whereData = {
                benchmarkId: {eq: benchmarkId},
                renterId: {eq: renterId}
            }


            // if(typeof eCategoryId==='string' || typeof eCategoryId==='number'){
            //     if(eCategoryId.toString().indexOf(',')>0){
            //         whereData.benchmark={categoryId:{in:eCategoryId.toString().split(',')}}
            //     }else{
            //         whereData.benchmark={categoryId:{eq:eCategoryId}}
            //     }
            //
            // }else if(eCategoryId===null){
            //     whereData.benchmark={categoryId:{nul:true}}
            // }

            request({
                url: 'api/miniapp/orm/benchmarkbind/agg',
                method: 'POST',
                params: {
                    where: whereData,
                    select: 'type,benchmarkId,benchmark.name benchmarkName,renterId,type,benchmark.quota benchmarkQuota,benchmark.subquota benchmarkSubquota,benchmark.unitId benchmarkUnitId'
                }
            }).then(res => {
                if (res.code == 0) {
                    let result = {values: [], years: [], name: null, startTime: null, renterId: renterId, unitId: null}
                    if (res.data?.data?.length > 0) {
                        let curData = res.data.data[0]


                        let startYear = startTime.getFullYear()
                        let endYear = endTime.getFullYear()

                        console.log(startYear)
                        console.log(endYear)

                        result.startTime = GF.getYearBegin(startTime)
                        result.name = curData.benchmarkName
                        result.type = curData.type
                        result.unitId = curData.benchmarkUnitId


                        for (let curYear = startYear; curYear <= endYear; curYear++) {

                            result.years.push(curYear)

                            if (timePeriod == '2') { // monthly:
                                if (curData.benchmarkSubquota?.length > 0) {

                                    for (let j = 0; j < yearDivisionAmount; j++) {
                                        result.values.push(curData.benchmarkSubquota.length > j ? curData.benchmarkSubquota[j].value : null)
                                    }

                                }
                            } else if (timePeriod == '1') { // yearly:
                                result.values.push(curData.benchmarkQuota)
                            }

                        }


                    }
                    resolve(result)

                } else {
                    reject(new Error('Error when loading index'))
                }
            })

        })

    }


    static trainDataGenerator(formData, apiName) {
        let resultData = new Error('NO-DATA')
        if (!formData) {
            return new Error('NO-DATA')
        }
        if (!apiName) {
            return new Error('NO-API')
        }
        switch (apiName.toLowerCase()) {
            case 'anomaly_detection_train':
                if (formData.dataOriginal) {
                    let list = formData.dataOriginal
                    let timeGranularity = ['1hour', '1day', '1month']
                    let timeFormatter = ['yyyy-MM-dd hh:mm:ss', 'yyyy-MM-dd', 'yyyy-MM']
                    let granularity = Number(formData.granularity)

                    // if(granularity==0 && list.length<720) return new Error('SHORT-DATA')
                    // if(granularity==1 && list.length<60) return new Error('SHORT-DATA')
                    // if(granularity==2 && list.length<12) return new Error('SHORT-DATA')


                    let result = {
                        input_data_method: 'json',
                        data_size: list.length,
                        time_granularity: timeGranularity[granularity],
                        detection_method: formData.detection_method,
                        algorithm: formData.algorithm,
                        data: []
                    }

                    list.forEach((it, idx) => {
                        result.data.push({
                            serial_no: idx,
                            device_id: it.device_id,
                            timestamp: GF.formatTime(new Date(it.timestamp), timeFormatter[granularity]),
                            value: Number(it.value) || 0
                        })
                    })

                    formData.data = result
                    resultData = formData
                }
                return resultData
            case 'time_series_forecasting_train':
                if (formData.dataOriginal) {
                    let list = formData.dataOriginal
                    let timeGranularity = ['1hour', '1day', '1month']
                    let timeFormatter = ['yyyy-MM-dd hh:mm:ss', 'yyyy-MM-dd', 'yyyy-MM']
                    let granularity = Number(formData.granularity)

                    // if(granularity==0 && list.length<720) return new Error('SHORT-DATA')
                    // if(granularity==1 && list.length<60) return new Error('SHORT-DATA')
                    // if(granularity==2 && list.length<12) return new Error('SHORT-DATA')

                    let result = {
                        input_data_method: 'json',
                        dataset_name: formData.datasetName,
                        data_size: list.length,
                        time_granularity: timeGranularity[granularity],
                        data: []
                    }

                    list.forEach((it, idx) => {
                        result.data.push({
                            serial_no: idx,
                            timestamp: GF.formatTime(new Date(it.timestamp), timeFormatter[granularity]),
                            value: Number(it.value) || 0
                        })
                    })

                    formData.data = result
                    resultData = formData
                }
                return resultData
            case 'ac_energy_hourly_forecasting_train':
                if (formData.dataOriginal) {
                    let list = formData.dataOriginal
                    // let timeGranularity= ['1hour','1day','1month']
                    // let timeFormatter=['yyyy-MM-dd hh:mm:ss','yyyy-MM-dd','yyyy-MM']
                    // let granularity = Number(formData.granularity)
                    let result = {
                        input_data_method: 'json',
                        dataset_name: formData.datasetName,
                        data_size: list.length,
                        data: []
                    }

                    list.forEach((it, idx) => {
                        result.data.push({
                            serial_no: idx,
                            timestamp: GF.formatTime(new Date(it.timestamp), 'yyyy-MM-dd hh:mm:ss'),
                            value: Number(it.value) || 0,
                            temperature: it.temperature,
                            humidity: it.humidity,
                            rainfall: it.rainfall,
                            uv_index: it.uv_index

                        })
                    })

                    formData.data = result
                    resultData = formData
                }
                return resultData
            case 'ac_energy_daily_monthly_forecast_train':
                if (formData.dataOriginal) {
                    let list = formData.dataOriginal
                    let timeGranularity = ['1hour', '1day', '1month']
                    let timeFormatter = ['yyyy-MM-dd hh:mm:ss', 'yyyy-MM-dd', 'yyyy-MM']
                    let granularity = Number(formData.granularity)
                    let result = {
                        input_data_method: 'json',
                        dataset_name: formData.datasetName,
                        data_size: list.length,
                        time_granularity: timeGranularity[granularity],
                        data: []
                    }
                    list.forEach((it, idx) => {
                        result.data.push({
                            serial_no: idx,
                            timestamp: GF.formatTime(new Date(it.timestamp), timeFormatter[granularity]),
                            value: Number(it.value) || 0,

                            max_temperature: it.max_temperature,
                            min_temperature: it.min_temperature,
                            mean_temperature: it.mean_temperature,
                            median_temperature: it.median_temperature,

                            max_humidity: it.max_humidity,
                            min_humidity: it.min_humidity,
                            mean_humidity: it.mean_humidity,
                            median_humidity: it.median_humidity,

                            max_rainfall: it.max_rainfall,
                            min_rainfall: it.min_rainfall,
                            mean_rainfall: it.mean_rainfall,
                            median_rainfall: it.median_rainfall,

                            max_uv_index: it.max_uv_index,
                            min_uv_index: it.min_uv_index,
                            mean_uv_index: it.mean_uv_index,
                            median_uv_index: it.median_uv_index,


                        })
                    })

                    formData.data = result
                    resultData = formData
                }
                return resultData
            default:
                return false

        }


    }

    static predictDataGenerator(formData, apiName) {
        let resultData = new Error('NO-DATA')
        if (!formData) {
            return new Error('NO-DATA')
        }
        if (!apiName) {
            return new Error('NO-API')
        }
        switch (apiName.toLowerCase()) {
            case 'anomaly_detection_check':
                if (formData.dataOriginal) {
                    let list = formData.dataOriginal
                    let timeGranularity = ['1hour', '1day', '1month']
                    let timeFormatter = ['yyyy-MM-dd hh:mm:ss', 'yyyy-MM-dd', 'yyyy-MM']
                    let granularity = Number(formData.granularity)

                    let result = {
                        data_size: list.length,
                        data: []
                    }

                    list.forEach((it, idx) => {
                        result.data.push({
                            serial_no: idx,
                            device_id: it.device_id,
                            algorithm: formData.algorithm,
                            time_granularity: timeGranularity[granularity],
                            detection_method: formData.detection_method,
                            timestamp: GF.formatTime(new Date(it.timestamp), timeFormatter[granularity]),
                            value: Number(it.value) || 0
                        })
                    })

                    formData.data = result
                    resultData = formData
                }
                return resultData
            case 'time_series_forecast':
                if (formData.dataOriginal) {
                    let list = formData.dataOriginal
                    // let timeGranularity= ['1hour','1day','1month']
                    let timeFormatter = ['yyyy-MM-dd hh:mm:ss', 'yyyy-MM-dd', 'yyyy-MM']
                    let granularity = Number(formData.granularity)
                    let timeUnits = ['h', 'd', 'm']


                    let result = {

                        dataset_name: formData.datasetName,
                        start_time: GF.formatTime(new Date(formData.start_time), 'yyyy-MM-dd hh:mm:ss'),
                        forecasting_period: formData.forecasting_period + timeUnits[granularity],
                        //time_granularity: timeGranularity[granularity],
                        historical_data: []
                    }

                    list.forEach((it) => {
                        result.historical_data.push({
                            // serial_no: idx,
                            timestamp: GF.formatTime(new Date(it.timestamp), timeFormatter[granularity]),
                            value: Number(it.value) || 0
                        })
                    })

                    formData.data = result
                    resultData = formData
                }
                return resultData

            case 'ac_energy_hourly_forecast':
                if (formData.dataOriginal) {
                    let list = formData.dataOriginal
                    // let timeGranularity= ['1hour','1day','1month']
                    let timeFormatter = ['yyyy-MM-dd hh:mm:ss', 'yyyy-MM-dd', 'yyyy-MM']
                    let granularity = Number(formData.granularity)
                    let timeUnits = ['h', 'd', 'm']

                    let result = {

                        dataset_name: formData.datasetName,
                        start_time: GF.formatTime(new Date(formData.start_time), 'yyyy-MM-dd hh:mm:ss'),
                        forecasting_period: formData.forecasting_period + timeUnits[granularity],
                        //time_granularity: timeGranularity[granularity],
                        historical_data: [],
                        historical_3_weather_data: [],
                        weather: []
                    }

                    list.forEach((it) => {
                        result.historical_data.push({
                            // serial_no: idx,
                            timestamp: GF.formatTime(new Date(it.timestamp), timeFormatter[granularity]),
                            value: Number(it.value) || 0
                        })
                    })

                    formData.data = result
                    resultData = formData
                }
                return resultData
            case 'ac_energy_daily_monthly_forecast':
                if (formData.dataOriginal) {
                    let list = formData.dataOriginal
                    // let timeGranularity= ['1hour','1day','1month']
                    let timeFormatter = ['yyyy-MM-dd hh:mm:ss', 'yyyy-MM-dd', 'yyyy-MM']
                    let granularity = Number(formData.granularity)
                    let timeUnits = ['h', 'd', 'm']

                    let result = {

                        dataset_name: formData.datasetName,
                        start_time: GF.formatTime(new Date(formData.start_time), 'yyyy-MM-dd hh:mm:ss'),
                        forecasting_period: formData.forecasting_period + timeUnits[granularity],
                        //time_granularity: timeGranularity[granularity],
                        historical_data: [],
                        historical_3_weather_data: [],
                        weather: []
                    }

                    list.forEach((it) => {
                        result.historical_data.push({
                            // serial_no: idx,
                            timestamp: GF.formatTime(new Date(it.timestamp), timeFormatter[granularity]),
                            value: Number(it.value) || 0
                        })
                    })

                    formData.data = result
                    resultData = formData
                }
                return resultData
            default:
                return false

        }


    }

    /**
     * getTimeByScale
     *    scale = year,  return currentYear/1/1 0:0:0:00
     *    scale = month, return currentYear/currentMonth/1 0:0:0:00
     *    scale = day,   return currentYear/currentMonth/currentDay 0:0:0:00
     *            hour
     *            minute/min
     *            second
     * @param currentTime {Date}: current time.
     * @param scale {string}: year/month/day/hour/minute/min/second
     * @returns {Date} : Date object.
     */
    static getTimeByScale(currentTime, scale) {
        scale = scale || 'day'
        let cur = new Date(currentTime)
        scale = scale.toLowerCase()

        cur.setMilliseconds(0)
        if (scale === 'second') return cur
        cur.setSeconds(0)
        if (scale === 'minute' || scale === 'min') return cur
        cur.setMinutes(0)
        if (scale === 'hour') return cur
        cur.setHours(0)
        if (scale === 'day') return cur
        cur.setDate(1)
        if (scale === 'month') return cur
        cur.setMonth(0)
        if (scale === 'year') return cur

    }


    static getChildrenSpace(spaceId) {
        return new Promise((resolve, reject) => {
            request({
                url: 'api/miniapp/orm/space/agg',
                method: 'POST',
                params: {
                    select: 'id,pid,timestamp,name,type,code',
                    withParent: false,
                    withChildren: false,
                    where: {
                        pid: {
                            "eq": spaceId
                        }
                    }
                }
            }).then(res => {
                if (res.code == 0 && res.data?.data) {
                    resolve(res.data.data)
                } else {
                    reject(new Error('failed loading children spaces with remote'))
                }
            }).catch(err => {
                reject(err)
            })
        })
    }

    static getChildrenSpaceIds(spaceId) {
        return new Promise((resolve, reject) => {
            this.getChildrenSpace(spaceId).then(res => {
                if (res?.length > 0) {
                    let list = res.map(it => it.id)
                    resolve(list)
                } else {
                    resolve(null)
                }
            }).catch(err => {
                reject(err)
            })
        })
    }

    static getChildrenSpaceIdsString(spaceId) {
        return new Promise((resolve, reject) => {
            this.getChildrenSpace(spaceId).then(res => {
                if (res?.length > 0) {
                    let list = res.map(it => it.id)
                    resolve(list.join(','))
                } else {
                    resolve(null)
                }
            }).catch(err => {
                reject(err)
            })
        })
    }

    static getDescendantsSpace(spaceId) {
        return new Promise((resolve, reject) => {
            request({
                url: 'api/miniapp/orm/space/agg',
                method: 'POST',
                params: {
                    select: 'id,pid,timestamp,name,type,code',
                    withParent: false,
                    withChildren: true,
                    where: {
                        pid: {
                            "eq": spaceId
                        }
                    }
                }
            }).then(res => {
                if (res.code == 0 && res.data?.data) {
                    resolve(res.data.data)
                } else {
                    reject(new Error('failed loading Descendants spaces with remote'))
                }
            }).catch(err => {
                reject(err)
            })
        })
    }

    static getDescendantsSpaceIds(spaceId) {
        return new Promise((resolve, reject) => {
            this.getDescendantsSpace(spaceId).then(res => {
                if (res?.length > 0) {
                    let list = res.map(it => it.id)
                    resolve(list)
                } else {
                    resolve(null)
                }
            }).catch(err => {
                reject(err)
            })
        })
    }

    static getDescendantsSpaceIdsString(spaceId) {
        return new Promise((resolve, reject) => {
            this.getDescendantsSpace(spaceId).then(res => {
                if (res?.length > 0) {
                    let list = res.map(it => it.id)
                    resolve(list.join(','))
                } else {
                    resolve(null)
                }
            }).catch(err => {
                reject(err)
            })
        })
    }

    /**
     * getTopLevelSpaceIds
     * get top-level space id list
     * @returns {Promise<unknown>} : resolve id list in array.
     */
    static loadTopSpaceNodesIntoLocal() {
        return new Promise((resolve, reject) => {
            request({
                url: 'api/miniapp/orm/space/agg',
                method: 'POST',
                params: {
                    select: 'id,pid,timestamp,name,type,code',
                    where: {
                        pid: {
                            "nul": true
                        }
                    }
                }
            }).then(res => {
                if (res.code == 0 && res.data?.data) {
                    window.localStorage.setItem('topSpaceNodeData', JSON.stringify(res.data.data))
                    resolve(res.data.data)
                } else {
                    reject(new Error('failed loading space with remote'))
                }
            }).catch(err => {
                reject(err)
            })
        })
    }


    static getTopSpaceNodes() {
        let list = window.localStorage.getItem('topSpaceNodeData')
        let result = []
        if (list) {
            try {
                result = JSON.parse(list)
            } catch (e) {
                result = []
            }
        }
        return result
    }// -/

    static getTopSpaceIds() {
        let topNodes = this.getTopSpaceNodes()
        let result = []
        if (topNodes?.length > 0) {
            result = topNodes.map(it => it.id)
        }
        return result
    }// -/

    static getTopSpaceIdsString() {
        let result = this.getTopSpaceIds()
        if (result?.length > 0) {
            return result.join(',')
        } else {
            return ''
        }
    }

    /**
     * getTimeFieldByTimeScale
     * get time field by timeScale, like 'sampleYear:to_char[yyyy] time'
     * @param timeScale {string}: all, year, month, day
     * @param fieldName {string}: field name , like 'samplePeriod',
     *                            when timeScaleMode==1, it means field name prefix only, like 'period' => 'periodYear'     *
     * @param fieldNameAlias {string}: field alias,
     * @param timeScaleMode {number/string}:  0[default] -- just change time format; 1- change field name and time format
     * @returns {string} : time field expression, like 'sampleYear:to_char[yyyy] time'
     */
    static getTimeFieldByTimeScale(timeScale, fieldName, fieldNameAlias, timeScaleMode) {
        let timeFormat
        let timeField
        let result
        switch (timeScale) {
            case 'year':
                timeFormat = 'yyyy'
                timeField = 'Year'
                break
            case 'month':
                timeFormat = 'yyyy-mm'
                timeField = 'Month'
                break
            case 'day':
                timeFormat = 'yyyy-mm-dd'
                timeField = 'Date'
                break
            case 'hour':
                timeFormat = 'yyyy-mm-dd hh'
                timeField = 'Hour'
                break
            default:
                timeFormat = 'yyyy-mm-dd'
                timeField = 'Date'
        }
        result = fieldName + ':to_char[' + timeFormat + '] ' + fieldNameAlias
        if (timeScaleMode == '1') {
            result = fieldName + timeField + ':to_char[' + timeFormat + '] ' + fieldNameAlias
        }
        return result
    }

    static checkPeriodOverlap(apiUrl, conditionParams, beginTime, endTime, beginTimeKey, endTimeKey, overlapAmountMin) {
        beginTimeKey = beginTimeKey || 'beginDate'
        overlapAmountMin = Number(overlapAmountMin) || 1
        // endTimeKey = endTimeKey || 'endDate'

        return new Promise((resolve, reject) => {

            if (!apiUrl || !beginTime) {
                reject(new Error('Invalid params'))
                return
            }


            let where

            where = {}

            if (!endTime && !endTimeKey) { // if no endTimeKey and no endTime value , then just check beginTime: if old beginTime > new beginTime, then overlapped.
                where[beginTimeKey] = {"ge": beginTime}

            } else { // if check begin and end time:
                if (endTime) {
                    where[beginTimeKey] = {"le": endTime}
                }
                where[endTimeKey] = {"ge": beginTime, "or": {"nul": true}}
            }


            let params = {}
            params.where = where

            params.where = Object.assign(params.where, conditionParams)

            request({
                url: apiUrl,
                method: 'POST',
                params: params
            }).then(res => {
                if (res.code == 0) {
                    if (res.data?.data?.length >= overlapAmountMin) {

                        resolve(true)
                    } else {
                        resolve(false)
                    }
                } else {
                    reject(new Error(res.message))
                }

                // where ={}
                // where[beginTimeKey]={"le": endTime}
                // where[endTimeKey]={"ge": endTime}
                // params.where = where
                // params.paging = {pageSize:1}
                // params.where = Object.assign(params.where, conditionParams)
                // request({
                //     url: apiUrl,
                //     method: 'POST',
                //     params: params
                // }).then(res2=>{
                //     if(res2.code==0){
                //         if(res2.data?.data?.length>0){
                //
                //             resolve(true)
                //         }else{
                //             resolve(false)
                //         }
                //     }else{
                //         reject(new Error(res2.message))
                //     }
                //
                // })

            }).catch(err => {
                reject(err)
            })
        })


    }


    /**
     * sortIncomingData
     * @param data
     * @param sortIncomeFields {array}: element format:
     *                                  {
     *                                      source: 'original key name',
     *                                      result: 'output result key name, if not provided or empty, then use source key name'
     *                                  }
     * @param excludeEmptyValue {boolean}:  exclude if value is empty. [default]- false
     * @returns {{}}
     */
    static sortIncomingData(data, sortIncomeFields, excludeEmptyValue) {
        excludeEmptyValue = Boolean(excludeEmptyValue)
        let incomeData = {}
        if (sortIncomeFields && sortIncomeFields.length > 0) {
            sortIncomeFields.forEach(it => {

                if (typeof GF.findKeyVal(data, it.source) === 'undefined') return
                const value = GF.getKeyVal(data, it.source, null)

                if (value === null && excludeEmptyValue) {
                    return
                }

                GF.setKeyVal(incomeData, it.result || it.source, value)   // isNaN(Number(value)) ? value : Number(value)

            })
        } else {
            incomeData = Object.assign({}, data)
        }
        return incomeData
    }// -/

    static getMonitorRefreshInterval() {
        let setting = this.getSystemSetting()
        console.log(setting)
        return setting?.refreshInterval?.value || 3000
    }

    static getSystemSetting() {
        let setString = window.localStorage.getItem('settings')
        if (setString) {
            let setting = {}
            try {
                setting = JSON.parse(setString)
            } catch (e) {
                return {}
            }
            return setting
        }
    }

    static getSystemSettingByKey(key) {
        let setting = this.getSystemSetting() || {}
        if (setting && setting[key]) {
            return setting[key].value
        } else {
            return undefined
        }
    }

    static auth(moduleName, functionName) {
        if (!moduleName) return true
        return checkAuth(moduleName + ':' + functionName)
    }

    static formatTime(time, fmt) {
        if (typeof time === 'undefined' || time === null) return ''
        if (typeof fmt !== 'string') return ''
        time = typeof time === 'number' ? new Date(time) : time


        const o = {
            'M+': time.getMonth() + 1, // 月份
            'd+': time.getDate(), // 日
            'h+': time.getHours(), // 小时
            'm+': time.getMinutes(), // 分
            's+': time.getSeconds(), // 秒
            'q+': Math.floor((time.getMonth() + 3) / 3), // 季度
            S: time.getMilliseconds() // 毫秒
        };
        if (/(y+)/.test(fmt)) {
            fmt = fmt.replace(
                RegExp.$1,
                (time.getFullYear() + '').substr(4 - RegExp.$1.length)
            )
        }
        for (let k in o) {
            if (new RegExp('(' + k + ')').test(fmt)) {
                fmt = fmt.replace(
                    RegExp.$1,
                    RegExp.$1.length === 1 ? o[k] : ('00' + o[k]).substr(('' + o[k]).length)
                )
            }
        }
        return fmt
    }

    /**
     * parseTimeScaleToPeriod
     * @param timeScale {string} :  all/ year/month/day/ periodIDXXXXXXX
     * @returns {any|string|number}
     */
    static parseTimeScaleToPeriod(timeScale) {

        if (typeof timeScale === 'undefined' || timeScale === null || timeScale === '') return 0

        let mp = new Map()
        mp.set('all', 0)
        mp.set('year', 1)
        mp.set('month', 2)
        mp.set('day', 3)

        // if(timeScale.indexOf('period:')>=0){
        //     let periodId = timeScale.replace('period:','')
        //     return '4,'+periodId
        // }else{
        //     return mp.get(timeScale) || 0
        // }
        // console.log('parse period======')
        // console.log(typeof mp.get(timeScale) !== 'undefined' ? mp.get(timeScale): '4,'+timeScale)

        return typeof mp.get(timeScale) !== 'undefined' ? mp.get(timeScale) : '4,' + timeScale


    }

    /**
     * getPeriodTimeFormat
     * get grouping time format by period
     * e.g.:  period = 1 (year) => return 'yyyy-mm'
     * @param period {number/string}: 0- all; 1- year; 2- month; 3-day;  other not empty string -- custom period id.
     * @returns {string} : 'yyyy-mm-dd' like timeFormat for api grouping.
     */
    static getPeriodGroupingTimeFormat(period) {
        let result = 'yyyy'
        if (GF.isValid(period)) {
            switch (period.toString()) {
                case '0':
                    result = 'yyyy'
                    break
                case '1':
                    result = 'yyyy-mm'
                    break
                case '2':
                    result = 'yyyy-mm-dd'
                    break
                case '3':
                    result = 'yyyy-mm-dd hh'
                    break

            }
        }
        return result
    }

    /**
     * getTimeScaleGroupingTimeFormat
     * get grouping time format by TimeScale
     * e.g.:  period = 1 (year) => return 'yyyy'
     * @param period {number/string}: 0- all => 'yyyy-mm-dd'; 1- year =>'yyyy'; 2- month => 'yyyy-mm'; 3-day => 'yyyy-mm-dd';  other not empty string -- custom period id.
     * @returns {string} : 'yyyy-mm-dd' like timeFormat for api grouping.
     */
    static getTimeScaleGroupingTimeFormat(period) {
        let result = 'yyyy-mm-dd'
        if (GF.isValid(period)) {
            switch (period.toString()) {
                case '1':
                    result = 'yyyy'
                    break
                case '2':
                    result = 'yyyy-mm'
                    break
                case '3':
                    result = 'yyyy-mm-dd'
                    break
            }
        }
        return result
    }

    /**
     * getTimeScaleNameByPeriod
     * get api name by period
     * @param period
     * @returns {string} : period 0 ==> 'date'; 1==> 'year'
     */
    static getTimeScaleNameByPeriod(period) {
        let result = 'date'
        switch (period.toString()) {
            case '1':
                result = 'year'
                break
            case '2':
                result = 'month'
                break
            case '3':
                result = 'date'
                break
        }
        return result
    }

    /**
     * isValid
     * check if value not undefined, not null, not empty string.
     * @param value
     * @returns {boolean}
     */
    static isValid(value) {
        return typeof value !== 'undefined' && value !== null && value.toString().trim() !== ''
    }

    /**
     * downloadFile
     * @param url : file full url
     * @param fileNameOnly: file name part, for downloading file name
     */
    static downloadFile(url, fileNameOnly) {
        const a = document.createElement('a')
        a.href = url
        a.target = 'blank'

        a.download = fileNameOnly || url
        document.body.appendChild(a)
        a.click()
        document.body.removeChild(a)
    }

    static downloadData(data, ext, fileName) {
        const elementA = document.createElement('a');

        ext = ext || 'txt'

        //文件的名称为时间戳加文件名后缀
        elementA.download = +(fileName ? fileName : new Date()) + "." + ext;
        elementA.style.display = 'none';

        //生成一个blob二进制数据，内容为json数据
        let content = typeof data === 'object' ? JSON.stringify(data) : data.toString()
        const blob = new Blob([content]);

        //生成一个指向blob的URL地址，并赋值给a标签的href属性
        elementA.href = URL.createObjectURL(blob);
        document.body.appendChild(elementA);
        elementA.click();
        document.body.removeChild(elementA);
    }


    static userId() {
        const userInfo = getUserInfo()
        return userInfo && userInfo.id ? userInfo.id : null
    }

    static orgId() {
        const userInfo = getUserInfo()
        return userInfo && userInfo.organizationId ? userInfo.organizationId : null
    }

    static isSuper() {
        const userInfo = getUserInfo()
        return userInfo && userInfo.isSuper ? userInfo.isSuper == '1' : false
    }

    static allowed(functionId) {
        return checkAuth(functionId)
    }

    static getUserInfo() {
        return getUserInfo()
    }


    /**
     * getBaseTime
     * get date time by params, if not given the return current date time.
     * @param baseTime
     * @returns {Date}
     */
    static getBaseTime(baseTime) {
        if (baseTime instanceof Date) return new Date(baseTime)
        if (typeof baseTime === 'number' || typeof baseTime === 'string') return new Date(baseTime)
        return new Date()
    }

    /**
     * getTimeBegin
     * get begin of time by year,month,day,hour,min,sec,millisec.
     * e.g.:  getTimeBegin(2022) => will return time of 2022-1-1 0:0:0:000 in date object.
     *        getTimeBegin(2022,1) => will return time of 2022-2-1 0:0:0:000 in date obejct.
     * @param year
     * @param month
     * @param day
     * @param hour
     * @param min
     * @param sec
     * @param millisec
     * @returns {Date}
     */
    static getTimeBegin(year, month, day, hour, min, sec, millisec) {

        let cur = new Date()
        if (year) {
            cur.setFullYear(year)
            cur.setMonth(month || 0)
            cur.setDate(day || 1)
            cur.setHours(hour || 0)
            cur.setMinutes(min || 0)
            cur.setSeconds(sec || 0)
            cur.setSeconds(sec || 0)
            cur.setMilliseconds(millisec || 0)
        }

        return cur

    }

    /**
     * addTime
     * add time to baseTime.
     * e.g.: addTime( time1, 0,0,1)  => increase date of time1 by 1.
     *       addTime( time1, 0,0,-1)  => decrease date of time1 by 1.
     *       addTime( time1, 1) => increase year of time1 by 1.
     *
     * @param baseTime {Date/string/number}
     * @param year
     * @param month
     * @param day
     * @param hour
     * @param min
     * @param sec
     * @param millisec
     * @returns {Date}
     */
    static addTime(baseTime, year, month, day, hour, min, sec, millisec) {
        const curDate = this.getBaseTime(baseTime)
        // console.log(curDate)
        if (!isNaN(Number(year)) && year !== 0) curDate.setFullYear(curDate.getFullYear() + year)
        if (!isNaN(Number(month)) && month !== 0) curDate.setMonth(curDate.getMonth() + month)
        if (!isNaN(Number(day)) && day !== 0) curDate.setDate(curDate.getDate() + day)
        if (!isNaN(Number(hour)) && hour !== 0) curDate.setHours(curDate.getHours() + hour)
        if (!isNaN(Number(min)) && min !== 0) curDate.setMinutes(curDate.getMinutes() + min)
        if (!isNaN(Number(sec)) && sec !== 0) curDate.setSeconds(curDate.getSeconds() + sec)
        if (!isNaN(Number(millisec)) && millisec !== 0) curDate.setMilliseconds(curDate.getMilliseconds() + millisec)

        // console.log(curDate)
        return curDate

    }

    /**
     * getTomorrow
     * get tomorrow 00:00
     * @params baseTime {null/number/string/Date}: base time. if not provided, then get as current time.
     * @returns {Date}
     */
    static getTomorrow(baseTime) {

        const curDate = this.getBaseTime(baseTime)
        curDate.setDate(curDate.getDate() + 1)
        curDate.setHours(0)
        curDate.setMinutes(0)
        curDate.setSeconds(0)
        curDate.setMilliseconds(0)
        return curDate
    }

    static getYearBegin(baseTime) {
        const curDate = this.getBaseTime(baseTime)
        curDate.setMonth(0)
        curDate.setDate(1)
        curDate.setHours(0)
        curDate.setMinutes(0)
        curDate.setSeconds(0)
        curDate.setMilliseconds(0)
        return curDate
    }

    static getMonthBegin(baseTime) {
        const curDate = this.getBaseTime(baseTime)
        curDate.setDate(1)
        curDate.setHours(0)
        curDate.setMinutes(0)
        curDate.setSeconds(0)
        curDate.setMilliseconds(0)
        return curDate
    }

    static getTodayBegin(baseTime) {
        const curDate = this.getBaseTime(baseTime)
        curDate.setHours(0)
        curDate.setMinutes(0)
        curDate.setSeconds(0)
        curDate.setMilliseconds(0)
        return curDate
    }

    static getWeekBegin(baseTime) {
        const curDate = this.getBaseTime(baseTime)
        let curDay = curDate.getDay()
        curDate.setDate(curDate.getDate() - curDay)
        curDate.setHours(0)
        curDate.setMinutes(0)
        curDate.setSeconds(0)
        curDate.setMilliseconds(0)

        return curDate
    }

    /**
     * getEfficiencyFieldByType
     * @param type {string} : efficiency type, like : 'area','outputValue','peopleCount'
     * @param valueType {string}: value type, like " 'consumption', 'carbon', 'cost','coal'
     */
    static getEfficiencyFieldByType(type, valueType) {

        switch (type.toLowerCase()) {
            case 'area':
                return valueType + 'AreaEfficiency'
            case 'outputvalue':
                return 'indexbaseOutputValue' + valueType.substr(0, 1).toUpperCase() + valueType.substr(1) + 'Efficiency'
            case 'peoplecount':
                return 'indexbasePeopleCount' + valueType.substr(0, 1).toUpperCase() + valueType.substr(1) + 'Efficiency'
            case 'consumption':
            case 'carbon':
            case 'cost':
            case 'coal':
                return valueType.toLowerCase()

        }
        return ''
    }

    static getCategory() {
        return new Promise((resolve, reject) => {
            request({
                url: 'api/miniapp/orm/energycategory',
                method: 'get',
            }).then(res => {
                if (res.code == 0 && res.data && res.data.data) {
                    let list = res.data.data
                    let result
                    result = list.map((it) => {
                        return {label: it.name, value: it.id}
                    })
                    resolve(result)
                } else {
                    reject(new Error('loaded data invalid'))
                }

            }).catch(err => {
                console.log(err)
                reject(err)
            })
        })

    }

    /**
     * loadCategoryDataIntoLocal
     * load category data into localstorage by keyname = 'energyCategoryData'
     */
    static loadCategoryDataIntoLocal() {
        request({
            url: 'api/miniapp/orm/energycategory/agg',
            method: 'POST',
            params: {
                orderBy: 'id DESC',
                select: 'name,id,icon,energySourceCategoryUnit.name unitName, energySourceCategoryUnit.symbol unitSymbol, energySourceCategoryUnit.factor unitFactor'
            }
        }).then(res => {
            if (res.code == 0 && res.data?.data) {
                let list = res.data.data
                console.log(list)
                let result
                result = list.map((it) => {
                    return {
                        label: it.name,
                        value: it.id,
                        unitName: it.unitName,
                        unitSymbol: it.unitSymbol,
                        icon: it.icon ? 'files/' + it.icon : null
                    }
                })
                console.log(result)
                window.localStorage.setItem('energyCategoryData', JSON.stringify(result))
            }

        }).catch(err => {
            console.log(err)
        })
    }// -/


    /**
     * loadUnitDataIntoLocal
     * load unit data into localstorage by keyname = 'unitData'
     */
    static loadUnitDataIntoLocal() {
        request({
            url: 'api/miniapp/orm/unit',
            method: 'GET',
            params: {
                pageSize: 99999
            }
        }).then(res => {
            if (res.code == 0 && res.data?.data) {
                let list = res.data.data
                console.log(list)
                window.localStorage.setItem('unitData', JSON.stringify(list))
            }

        }).catch(err => {
            console.log(err)
        })
    }// -/

    static getUnitData() {
        let list = window.localStorage.getItem('unitData')
        let result = []
        if (list) {
            try {
                result = JSON.parse(list)
            } catch (e) {
                result = []
            }
        }
        return result
    }// -/

    static getUnitById(unitId) {
        if (!unitId) return {}
        let list = this.getUnitData()
        if (list.length > 0) {
            return list.find(it => it.id == unitId) || {}
        } else {
            return {}
        }
    }// -/


    static loadIndexBaseIntoLocal() {
        request({
            url: 'api/miniapp/orm/spacerenterindexbase/agg',
            method: 'POST',
            params: {
                orderBy: 'id DESC',
                select: 'name,id,type,unitId,unit.name unitName,unit.symbol unitSymbol'
            }
        }).then(res => {
            if (res.code == 0 && res.data?.data) {
                let list = res.data.data
                console.log(list)
                window.localStorage.setItem('indexBaseData', JSON.stringify(list))
            }

        }).catch(err => {
            console.log(err)
        })
    }

    static loadPeriodIntoLocal() {
        request({
            url: 'api/miniapp/orm/period/agg',
            method: 'POST',
            params: {
                paging: {pageSize: 99999},
                orderBy: 'id DESC',
                select: 'code,name,organizationId,organization.name organizationName,fromDate,toDate,id'
            }
        }).then(res => {
            if (res.code == 0 && res.data?.data) {
                let list = res.data.data
                console.log(list)
                window.localStorage.setItem('periodData', JSON.stringify(list))
            }

        }).catch(err => {
            console.log(err)
        })
    }

    static loadSamplePeriodMinMaxIntoLocal() {
        request({
            url: 'api/miniapp/orm/perioddim/agg',
            method: 'POST',
            data: {
                select: "date:max MAX,date:min MIN",

            }
        }).then(res => {
            console.log(res)
            if (res.code == 0 && res.data?.data?.length > 0) {

                window.localStorage.setItem('samplePeriodMin', res.data.data[0].MIN)
                window.localStorage.setItem('samplePeriodMax', res.data.data[0].MAX)

            }
        }).catch(err => {
            console.log(err)
        })
    }

    static getSamplePeriodMinDate() {
        let value = window.localStorage.getItem('samplePeriodMin')
        return new Date(Number(value))
    }

    static getSamplePeriodMaxDate() {
        let value = window.localStorage.getItem('samplePeriodMax')
        return new Date(Number(value))
    }


    static getCategoryDataById(catId) {
        if (!catId) return {}
        let catData = this.getCategoryFromLocal()
        if (catData) {
            return catData.find(it => it.value === catId) || {}
        } else {
            return {}
        }
    }// -/

    static getCategoryByIdx(idx) {
        if (isNaN(Number(idx)) || Number(idx) < 0) return {}
        let catData = this.getCategoryFromLocal()
        if (catData && catData.length > idx) {
            return catData[idx]
        } else {
            return null
        }
    }// -/

    static getCategoryUnitById(catId) {
        return this.getCategoryDataById(catId).unitSymbol || ''
    }

    static getUnitByCategoryId(catId) {
        return this.getCategoryUnitById(catId)
    }

    static getCategoryIdByIdx(idx) {
        let catData = this.getCategoryByIdx(idx)
        if (catData) {
            return catData.value
        } else {
            return null
        }
    }// -/

    /**
     * getCategoryFromLocal
     * @returns {*[]} : if not found then return []
     */
    static getCategoryFromLocal() {
        let list = window.localStorage.getItem('energyCategoryData')
        let result = []
        if (list) {
            try {
                result = JSON.parse(list)
            } catch (e) {
                result = []
            }
        }
        return result
    }// -/

    static getUnitByIndexType(indexType, categoryId) {
        console.log(indexType, categoryId)
        let catUnit = categoryId ? this.getCategoryUnitById(categoryId) : ''
        let unit = ''
        switch (indexType) {
            case 'A00':
                unit = catUnit
                break
            case 'A10':
                unit = catUnit + '/m²'
                break
            case 'A20':
                unit = catUnit + '/人'
                break
            case 'A30':
                unit = catUnit + '/元'
                break
            case 'B01':
                unit = 'kg'
                break
            case 'B11':
                unit = 'kg/m²'
                break
            case 'B21':
                unit = 'kg/人'
                break
            case 'B31':
                unit = 'kg/元'
                break
        }
        return unit
    }//-/

    static getUnitByConsumptionField(fieldName, categoryId) {
        let catUnit = categoryId ? this.getCategoryUnitById(categoryId) : ''
        let unit = ''
        switch (fieldName) {
            case 'consumption':
                unit = catUnit
                break
            case 'carbon':
                unit = this.getCarbonUnit()
                break
            case 'consumptionAreaEfficiency':
                unit = catUnit + '/m²'
                break
            case 'indexbasePeopleCountConsumptionEfficiency':
                unit = catUnit + '/人'
                break
            case 'indexbaseOutputValueConsumptionEfficiency':
                unit = catUnit + '/元'
                break
            case 'carbonAreaEfficiency':
                unit = 'kg/m²'
                break
            case 'indexbasePeopleCountCarbonEfficiency':
                unit = 'kg/人'
                break
            case 'indexbaseOutputValueCarbonEfficiency':
                unit = 'kg/元'
                break
        }
        return unit
    }//-/

    static getEfficiencyNameByField(fieldName) {
        let name = ''
        switch (fieldName) {
            case 'consumption':
                name = '能耗'
                break
            case 'carbon':
                name = '碳排放'
                break
            case 'consumptionAreaEfficiency':
                name = '面积能耗'
                break
            case 'indexbasePeopleCountConsumptionEfficiency':
                name = '人均能耗'
                break
            case 'indexbaseOutputValueConsumptionEfficiency':
                name = '产值能耗'
                break
            case 'carbonAreaEfficiency':
                name = '面积碳排放'
                break
            case 'indexbasePeopleCountCarbonEfficiency':
                name = '人均碳排放'
                break
            case 'indexbaseOutputValueCarbonEfficiency':
                name = '产值碳排放'
                break
        }
        return name
    }// -/

    static getUnitByCarbonField(fieldName, categoryId) {
        this.dustbin(fieldName + categoryId)
        return this.getCarbonUnit()
    }

    /**
     * getIndexBaseFromLocal
     * @returns {*[]} : if not found then return []
     */
    static getIndexBaseFromLocal() {
        let list = window.localStorage.getItem('indexBaseData')
        let result = []
        if (list) {
            try {
                result = JSON.parse(list)
            } catch (e) {
                result = []
            }
        }
        return result
    }// -/

    /**
     * getPeriodFromLocal
     * @returns {*[]} : if not found then return []
     */
    static getPeriodFromLocal() {
        let list = window.localStorage.getItem('periodData')
        let result = []
        if (list) {
            try {
                result = JSON.parse(list)
            } catch (e) {
                result = []
            }
        }
        return result
    }// -/

    static getPeriodDataById(id) {
        if (!id) return {}
        let data = this.getPeriodFromLocal()
        if (data) {
            return data.find(it => it.value === id) || {}
        } else {
            return null
        }
    }

    static getIndexDataById(id) {
        if (!id) return null
        let data = this.getIndexBaseFromLocal()
        if (data) {
            return data.find(it => it.id === id) || null
        } else {
            return null
        }
    }

    /**
     * mergeKeysInArray
     * e.g.:
     *       mergeKeysInArray( [{aa:1,bb:2},{aa:2,bb:4}] , 'aa,bb', 'cc', true )  => [{cc:3},{cc:6}]
     *       mergeKeysInArray( [{aa:1,bb:2},{aa:2,bb:4}] , 'aa,bb', null, true )  => [{aa_bb:3},{aa_bb:6}]
     *       mergeKeysInArray( [{aa:1,bb:2},{aa:2,bb:4}] , 'aa,bb', null, false )  => [{aa:1,bb:2,aa_bb:3},{aa:2,bb:4,aa_bb:6}]
     * @param list {array} : list data.
     * @param mergeKeys {string/Array}: merged key name list in string (sep with comma) or in array
     * @param newKeyName {string}: new key name for concatted
     * @param isRemoveKeysAfterMerge {boolean}: false[default] - do not remove merged keys; true- remove merged keys, keep new key only.
     */
    static mergeKeysInArray(list, mergeKeys, newKeyName, isRemoveKeysAfterMerge, mergeSep) {
        if (!list || list.length <= 0 || !mergeKeys) return list
        let keys = Array.isArray(mergeKeys) ? mergeKeys : (typeof mergeKeys === 'string' ? mergeKeys.split(',') : [])
        if (keys.length <= 0) return list
        newKeyName = newKeyName || keys.join('_')
        isRemoveKeysAfterMerge = Boolean(isRemoveKeysAfterMerge)

        mergeSep = mergeSep || ''

        list.forEach(it => {
            if (it) {
                let isFirst = true
                let merged
                keys.forEach(key => {
                    let curVal = GF.getKeyVal(it, key)
                    if (curVal === null || typeof curVal === 'undefined') return
                    if (isFirst) {

                        if (typeof curVal === 'string') {
                            merged = ''
                        } else if (typeof curVal === 'number') {
                            merged = 0
                        } else if (Array.isArray(curVal)) {
                            merged = []
                        } else {
                            merged = undefined
                        }
                    }

                    if (typeof merged === 'undefined') return

                    if (typeof merged === 'string' || typeof merged === 'number') {
                        if (mergeSep && !isFirst) {
                            merged += mergeSep + curVal
                        } else {
                            merged += curVal
                        }

                    } else if (Array.isArray(merged)) {
                        merged = merged.concat(Array.isArray(curVal) ? curVal : [curVal])
                    } else {
                        return
                    }


                    if (isRemoveKeysAfterMerge) {
                        delete it[key]
                    }

                    isFirst = false
                })
                it[newKeyName] = merged
            }
        })

        return list

    }// -/

    static deleteArrayByKeyVal(list, keyName, keyValue) {
        if (Array.isArray(list)) {
            for (let i = 0; i < list.length; i++) {
                let it = list[i]
                if (this.getKeyVal(it, keyName) === keyValue) {
                    list.splice(i, 1)
                    i--
                }
            }

        } else {
            return list
        }
    }

    /**
     * copyData
     * copy data by includeKeys
     * [important] only work on pure data, not work on function object.
     * @param data {object}:  data object
     * @param includeKeys {array/string}: key names list in Array or in string separated by ','
     *                                    array: object array.
     *                                           .source -- original key name
     *                                           .result -- result key name.
     *                                           .mapping -- value mapping function
     * @returns {{}}
     */
    static copyData(data, includeKeys) {
        let result = {}
        let keys = []
        if (Array.isArray(data)) {
            return this.copyArrayData(data, includeKeys)
        }
        if (typeof includeKeys == 'string') {
            keys = includeKeys.split(',')
        } else if (Array.isArray(includeKeys)) {
            keys = includeKeys
        }
        let setKey = (keyName) => {
            let sourceKey, resultKey, mapping
            let defaultMap = (v) => {
                return v
            }

            if (typeof keyName == 'object') {
                sourceKey = keyName.source
                resultKey = keyName.result || keyName.source
                mapping = keyName.mapping || defaultMap
            } else {
                sourceKey = keyName
                resultKey = keyName
                mapping = defaultMap
            }

            if (typeof data[sourceKey] == 'object') {
                try {
                    result[resultKey] = mapping(JSON.parse(JSON.stringify(data[sourceKey])))
                } catch (e) {
                    console.log(e)
                }
            } else {
                if (typeof keyName == 'string' && keyName.indexOf('=>') >= 0) {
                    sourceKey = keyName.substring(0, keyName.indexOf('=>'))
                    resultKey = keyName.substring(keyName.indexOf('=>') + 2)
                }
                result[resultKey] = mapping(data[sourceKey])
            }
        }
        if (keys.length <= 0) { // copy all keys if includeKeys empty
            for (let key in data) {
                setKey(key)
            }
        } else { // copy included keys only
            for (let key in keys) {
                setKey(keys[key])
            }
        }

        return result

    }// -/


    /**
     * copyArrayData
     * copy data array by includeKeys.
     * @param list {array}: array of object
     * @param includeKeys {array/string}: key names list in Array or in string separated by ','
     * @returns {*} : copied new array of data.
     */
    static copyArrayData(list, includeKeys) {
        let result = []

        if (Array.isArray(list)) {
            list.forEach((item) => {
                const newItem = this.copyData(item, includeKeys)
                result.push(newItem)
            })
        } else if (typeof list == 'object') {
            return this.copyData(list, includeKeys)
        }
        return result
    }// -/

    static setKeyVal(data, keyString, value) {
        if (typeof keyString !== 'string') return data
        keyString = keyString.trim()
        if (!keyString) return data

        value = typeof value === 'undefined' ? null : value

        if (typeof data !== 'object') {
            data = {}
        }

        let keyList = keyString.toString().split('.')
        let curData = data
        for (let i = 0; i < keyList.length; i++) {
            let key = keyList[i]
            if (i < keyList.length - 1) {
                if (typeof curData[key] === 'undefined') curData[key] = {}
                curData = curData[key]
            } else {
                curData[key] = value
            }
        }
        return data
    }// -/


    /**
     * sortIncomeData
     * sort incoming data when setData function by setting.
     * e.g.: sortIncomeData ( {aa:1,bb:2} , [{source:'aa',result:'AA'},{source:'bb', result:'BB'}])
     *       => {AA:1,BB:2}
     * ** source key-name supports '.' like 'aa.bb'
     * @param data
     * @param fieldSetting {array} : element format:  { source: 'source key name', result: 'sorted result key name'}
     * @returns {{}}
     */
    static sortIncomeData(data, fieldSetting) {
        let incomeData = {}
        if (fieldSetting && fieldSetting.length > 0) {
            fieldSetting.forEach(it => {
                const value = GF.getKeyVal(data, it.source, null)
                if (value !== null) {
                    let resultKey = it.result || it.source
                    let setValue = isNaN(Number(value)) ? value : Number(value)
                    incomeData[resultKey] = setValue
                    // incomeData= this.setKeyVal(incomeData, resultKey,setValue)
                }
            })
        } else {
            incomeData = Object.assign({}, data)
        }
        return incomeData
    }// -/

    /**
     * hasKey
     * if cur data has key or not
     * key string support '.' and '[0]' , '[key=10]'
     * like 'user.name' or 'users[1]' or 'users[name=jack]'
     * @param data
     * @param key
     * @returns {boolean}
     */
    static hasKey(data, key) {
        let res = this.findKeyVal(data, key)
        return typeof res !== 'undefined'
    }

    /**
     * findKeyVal
     * get value by key. key string support '.' and '[0]' , '[key=10]'.
     * like 'user.name' or 'users[1]' or 'users[name=jack]'
     * if not found key, then return undefined.
     * @param data
     * @param keyString
     * @returns {*|undefined}
     */
    static findKeyVal(data, keyString) {
        let defaultValue = undefined
        keyString = keyString.trim()
        if (!keyString) return defaultValue

        if (keyString.indexOf('.') === -1 && keyString.indexOf('[') === -1) {// direct key:
            // console.log(keyString)
            // console.log(data)
            // console.log(Object.prototype.hasOwnProperty.call(data, keyString))
            return data && Object.prototype.hasOwnProperty.call(data, keyString) ? data[keyString] : defaultValue
        } else {

            let keyList = keyString.toString().split('.')

            let _findKey = (keys, curData) => {
                if (!keys || !curData || keys.length <= 0) return defaultValue

                let curKey = keys[0]

                let pat = /\[[^\]]+\]/
                let matches = curKey.match(pat)
                if (matches && matches.length > 0) { //key string indicates array

                    let curKeyPure = curKey.replace(matches[0], '')
                    // console.log(curKeyPure)

                    if ((curKeyPure.length > 0 && Object.prototype.hasOwnProperty.call(curData, curKeyPure) && Array.isArray(curData[curKeyPure])) || (curKeyPure.length === 0 && Array.isArray(curData))) { // if data has current key and is array
                        let curArrayData = curKeyPure.length > 0 ? curData[curKeyPure] : curData
                        let keyStr = matches[0]
                        keyStr = keyStr.replace(/\[|\]/g, '')
                        // console.log(keyStr)
                        if (!isNaN(parseInt(keyStr))) { // keyStr is array index, and current data has this index.
                            if (curArrayData.length > parseInt(keyStr)) {

                                // if final key:
                                if (keys.length === 1) return curArrayData[parseInt(keyStr)]
                                // else , still have sub key:
                                const subData = curArrayData[parseInt(keyStr)]
                                keys.splice(0, 1)
                                return _findKey(keys, subData)

                            }
                        } else {
                            const eqLoc = keyStr.indexOf('=')
                            if (eqLoc > 0) {// keyStr is key compare expression

                                const compKey = keyStr.substring(0, eqLoc)
                                const compVal = keyStr.substr(eqLoc + 1)
                                let foundItem = null
                                let isFound = false
                                for (let j = 0; j < curArrayData.length; j++) {
                                    if (curArrayData[j][compKey] && curArrayData[j][compKey] == compVal) {
                                        foundItem = curArrayData[j]
                                        isFound = true
                                        break
                                    }
                                }
                                if (isFound) {
                                    // if final key:
                                    if (keys.length === 1) return foundItem
                                    // else , still have sub key:
                                    const subData = foundItem
                                    keys.splice(0, 1)
                                    return _findKey(keys, subData)
                                }
                            }
                        }
                        return defaultValue

                    } else { // if data has no current key or is not array, then return defaultValue.
                        return defaultValue
                    }


                } else {
                    if (Object.prototype.hasOwnProperty.call(curData, keys[0])) {
                        // if final key:
                        if (keys.length === 1) return curData[keys[0]]
                        // else , still have sub key:
                        const subData = curData[keys[0]]
                        keys.splice(0, 1)
                        return _findKey(keys, subData)

                    } else {
                        return defaultValue
                    }
                }

            }// -/

            return _findKey(keyList, data)
        }
    }// -/

    /**
     * getKeyVal
     * get value by key. key string support '.' and '[0]' , '[key=10]'.
     * like 'user.name' or 'users[1]' or 'users[name=jack]'
     * if not found, return null or defaultValue.
     * if defaultValue is undefined, then return null.
     * [IMPORTANT]: can NOT return undefined in any circumstance
     * @param data
     * @param keyString
     * @param defaultValue
     * @returns {*}
     */
    static getKeyVal(data, keyString, defaultValue) {
        defaultValue = typeof defaultValue === 'undefined' ? null : defaultValue

        if (!keyString || typeof keyString !== 'string') return defaultValue
        keyString = keyString.trim()
        if (keyString.indexOf('.') === -1 && keyString.indexOf('[') === -1) {// direct key:
            return data && Object.prototype.hasOwnProperty.call(data, keyString) ? data[keyString] : defaultValue
        } else {

            let keyList = keyString.toString().split('.')

            let _findKey = (keys, curData) => {
                if (!keys || !curData || keys.length <= 0) return defaultValue

                let curKey = keys[0]

                let pat = /\[[^\]]+\]/
                let matches = curKey.match(pat)
                if (matches && matches.length > 0) { //key string indicates array

                    let curKeyPure = curKey.replace(matches[0], '')
                    // console.log(curKeyPure)

                    if ((curKeyPure.length > 0 && Object.prototype.hasOwnProperty.call(curData, curKeyPure) && Array.isArray(curData[curKeyPure])) || (curKeyPure.length === 0 && Array.isArray(curData))) { // if data has current key and is array
                        let curArrayData = curKeyPure.length > 0 ? curData[curKeyPure] : curData
                        let keyStr = matches[0]
                        keyStr = keyStr.replace(/\[|\]/g, '')
                        // console.log(keyStr)
                        if (!isNaN(parseInt(keyStr))) { // keyStr is array index, and current data has this index.
                            if (curArrayData.length > parseInt(keyStr)) {

                                // if final key:
                                if (keys.length === 1) return curArrayData[parseInt(keyStr)]
                                // else , still have sub key:
                                const subData = curArrayData[parseInt(keyStr)]
                                keys.splice(0, 1)
                                return _findKey(keys, subData)

                            }
                        } else {
                            const eqLoc = keyStr.indexOf('=')
                            if (eqLoc > 0) {// keyStr is key compare expression

                                const compKey = keyStr.substring(0, eqLoc)
                                const compVal = keyStr.substr(eqLoc + 1)
                                let foundItem = null
                                for (let j = 0; j < curArrayData.length; j++) {
                                    if (curArrayData[j][compKey] && curArrayData[j][compKey] == compVal) {
                                        foundItem = curArrayData[j]
                                        break
                                    }
                                }
                                if (foundItem) {
                                    // if final key:
                                    if (keys.length === 1) return foundItem
                                    // else , still have sub key:
                                    const subData = foundItem
                                    keys.splice(0, 1)
                                    return _findKey(keys, subData)
                                }
                            }
                        }
                        return defaultValue

                    } else { // if data has no current key or is not array, then return defaultValue.
                        return defaultValue
                    }


                } else {
                    if (Object.prototype.hasOwnProperty.call(curData, keys[0])) {
                        // if final key:
                        if (keys.length === 1) return curData[keys[0]]
                        // else , still have sub key:
                        const subData = curData[keys[0]]
                        keys.splice(0, 1)
                        return _findKey(keys, subData)

                    } else {
                        return defaultValue
                    }
                }

            }// -/

            return _findKey(keyList, data)
        }
    }// -/
}
